import React, { useState } from 'react';

const Spoiler = ({ children }) => {
  const [isRevealed, setIsRevealed] = useState(false);

  const toggleVisibility = () => {
    setIsRevealed(!isRevealed);
  };

  return (
    <span
      onClick={toggleVisibility}
      className={`max-w-fit cursor-pointer ${isRevealed ? 'bg-transparent' : 'bg-tertiary text-transparent'}`}
    >
      {children}
    </span>
  );
};

export default Spoiler;