import React from 'react';
import { Helmet } from 'react-helmet';

const ResetPasswordSuccessful = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <Helmet>
        <meta name="reset-password-successful" content="Successfully reset password" />
        <title>Reset Password Successfully</title>
      </Helmet>
      <div className="flex flex-col justify-center items-center p-4 rounded-md">
        <h1 className="text-2xl mb-4 text-center">Password Reset Successful</h1>
        <p className="mb-4 text-center">Your password has been reset successfully. You can now log in with your new password.</p>
        <a href='/user/login' className="bg-red-400 text-white py-2 px-4 rounded-md hover:bg-tertiary transition duration-300">Login</a>
      </div>
    </div>
  );
};

export default ResetPasswordSuccessful;