import React from "react";
import { Helmet } from 'react-helmet';

import Info from "../components/Info";
import Sidebar from "../components/Sidebar";
import Hero from "../components/Hero";

function About () {
    const title = "About Us";
    const content = `
        Welcome to Crimson Translations!
        <br /><br />
        At Crimson Translations, we are dedicated to bringing the rich narratives of Chinese and Japanese short stories to a wider audience, with a particular emphasis on BL (Boys' Love) genres. Our mission is to deliver high-quality translations that stay true to the original text while being accessible to English-speaking readers.
        <br /><br />
        <h2 class="underline">Our Vision</h2>
        We envision a world where the beauty and complexity of Asian literature are accessible to everyone, fostering greater global understanding and appreciation of diverse cultures and stories. Our focus on BL genres aims to highlight these stories' emotional depth and cultural significance.
        <br /><br />
        <h2 class="underline">Our Commitment</h2>
        Quality Translations: We ensure that each translation preserves the original's tone, style, and cultural nuances.
        <br />
        Reader Engagement: We actively engage with our readers, valuing their feedback and suggestions to improve and expand our content.
        <br /><br />
        Don't miss out on any updates from us! Follow us on our social media platforms:
        <br /><br />
        Facebook: Crimson Translations
        <br />
        Instagram: @CrimsonTranslations
    `;

    return (
        <div className="flex-col">
            <div className='flex flex-col lg:flex-row justify-center items-center lg:items-start m-5 gap-10 max-w-full'>
                <Helmet>
                    <meta name="description" content={content} />
                    <title>About Us</title>
                </Helmet>
                <Info title={title} content={content} />
                <div className='justify-start items-start lg:max-w-xs'>
                    <Sidebar />
                </div>
            </div>
        </div>
    );
}

export default About;