import React, { createContext, useState, useEffect } from 'react';

const SettingsContext = createContext();

const fontSizes = ['text-xs', 'text-sm', 'text-md', 'text-lg', 'text-xl', 'text-2xl', 'text-3xl'];
const lineHeights = ['leading-10', 'leading-[60px]'];
const pageSpaces = ['px-1', 'px-2', 'px-3', 'px-4', 'px-5'];

const SettingsProvider = ({ children }) => {
    const [fontSizeIndex, setFontSizeIndex] = useState(3); // Default font size index (text-md)
    const [isMaxFontSize, setIsMaxFontSize] = useState(false);
    const [isMinFontSize, setIsMinFontSize] = useState(false);

    const [lineHeightIndex, setLineHeightIndex] = useState(0); // Default line height index (leading-10)

    const [pageSpaceIndex, setPageSpaceIndex] = useState(2); // Default page space index (px-3)
    const [isMaxPageSpace, setIsMaxPageSpace] = useState(false);
    const [isMinPageSpace, setIsMinPageSpace] = useState(false);

    useEffect(() => {
        const savedFontSizeIndex = localStorage.getItem('fontSizeIndex');
        if (savedFontSizeIndex !== null) setFontSizeIndex(Number(savedFontSizeIndex));
        
        const savedPageSpaceIndex = localStorage.getItem('pageSpaceIndex');
        if (savedPageSpaceIndex !== null) setPageSpaceIndex(Number(savedPageSpaceIndex));
    }, []);

    useEffect(() => {
        localStorage.setItem('fontSizeIndex', fontSizeIndex);
        if (fontSizeIndex === fontSizes.length - 1) {
            setIsMaxFontSize(true);
            setIsMinFontSize(false);
        } else if (fontSizeIndex === 0) {
            setIsMaxFontSize(false);
            setIsMinFontSize(true);
        } else {
            setIsMaxFontSize(false);
            setIsMinFontSize(false);
        }
        fontSizeIndex < 5 ? setLineHeightIndex(0) : setLineHeightIndex(1);
    }, [fontSizeIndex]);

    useEffect(() => {
        localStorage.setItem('pageSpaceIndex', pageSpaceIndex);
        if (pageSpaceIndex === pageSpaces.length - 1) {
            setIsMaxPageSpace(true);
            setIsMinPageSpace(false);
        } else if (pageSpaceIndex === 0) {
            setIsMaxPageSpace(false);
            setIsMinPageSpace(true);
        } else {
            setIsMaxPageSpace(false);
            setIsMinPageSpace(false);
        }
    }, [pageSpaceIndex]);

    const increaseFontSize = () => {
        if (fontSizeIndex < fontSizes.length - 1) {
            setFontSizeIndex(fontSizeIndex + 1);
        }
    };

    const decreaseFontSize = () => {
        if (fontSizeIndex > 0) {
            setFontSizeIndex(fontSizeIndex - 1);
        }
    };

    const increasePageSpace = () => {
        if (pageSpaceIndex < pageSpaces.length - 1) {
            setPageSpaceIndex(pageSpaceIndex + 1);
        }
    };

    const decreasePageSpace = () => {
        if (pageSpaceIndex > 0) {
            setPageSpaceIndex(pageSpaceIndex - 1);
        }
    };

    return (
        <SettingsContext.Provider value={{
            fontSize: fontSizes[fontSizeIndex],
            lineHeight: lineHeights[lineHeightIndex],
            increaseFontSize,
            decreaseFontSize,
            isMaxFontSize,
            isMinFontSize,
            pageSpace: pageSpaces[pageSpaceIndex],
            increasePageSpace,
            decreasePageSpace,
            isMaxPageSpace,
            isMinPageSpace,
        }}>
            {children}
        </SettingsContext.Provider>
    );
};

export { SettingsContext, SettingsProvider };
