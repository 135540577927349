import React from "react";
import clsx from "clsx";

import OneBookCard from "./OneBookCard.js";
import InFeed from "./ads/InFeed.js";

function BookCards({ book_list }) {
    // Check if the book list is present and has content
    if (!book_list || book_list.length === 0) {
        return <p className="text-center">No stories match the criteria!</p>;
    }

    return (
        <div className="grid lg:grid-cols-2 gap-3 min-w-[250px] m-2 max-w-full">
            {book_list.map((book) => (
                <div key={book.book_id}>
                    <OneBookCard 
                        book_id={book.book_id}
                        cover_image={book.cover_image}
                        english_book_name={book.english_book_name}
                        author={book.author}
                        book_word_count={book.book_word_count}
                        diversity={book.diversities}
                        completion_status={book.completion_statuses}
                        description={book.description}
                        last_updated={book.last_updated}
                        original_book_name={book.last_updated}
                        latest_chapter={book.latest_chapter}
                        latest_chapter_title={book.latest_chapter_title}
                        like_count={book.like_count}
                        has_new_updates={book.has_new_updates}
                    />
                </div>
            ))}            
        </div>
    );
}

export default BookCards;