import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../components/users/AuthContext";
import { Helmet } from "react-helmet";

function EmailVerification() {
    const location = useLocation();
    const [message, setMessage] = useState('');
    const { logout } = useContext(AuthContext);

    // Function to parse the query string
    const getTokenFromQuery = () => {
        const params = new URLSearchParams(location.search);
        return params.get('token');
    };

    const getEmailFromQuery = () => {
        const params = new URLSearchParams(location.search);
        return params.get('email');
    };

    useEffect(() => {
        const verifyEmail = async () => {
            const token = getTokenFromQuery();
            const email = getEmailFromQuery();
            if (token) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/verify-email`, {
                        params: { token: token, email: email }
                    });
                    setMessage(response.data.message);
                    logout();
                } catch (error) {
                    setMessage('An error occurred. Please try again.');
                }
            } else {
                setMessage('Invalid token.');
            }
        };

        verifyEmail();
    }, [location.search]);

    return (
        <div className="flex justify-center items-center">
            <Helmet>
                <meta name="email-verification" content="Email verification" />
                <title>Email Verification</title>
            </Helmet>
            <div className="flex flex-col justify-center items-center p-4 rounded-md w-full lg:w-1/2">
                <h2 className="text-2xl mb-4">Email Verification</h2>
                <p className="mb-4">{message}</p>
                <a href='/user/login' className="bg-red-400 text-white py-2 px-4 rounded-md hover:bg-tertiary transition duration-300">Login</a>
            </div>
        </div>
    );
}

export default EmailVerification;