import React, { useContext } from "react";
import { ClockIcon, LikeIcon } from "./icons/Icons";
import { ThemeContext } from "./ThemeContext";

function OneBookCard ( { book_id, cover_image, english_book_name, author, book_word_count, diversity, completion_status, 
    description, last_updated, original_book_name, latest_chapter, latest_chapter_title, like_count, has_new_updates } ) {
    // Create slug with the format of bookID-bookName
    // 1. Combine the ID and Name with a hyphen, ensuring there's a space around the hyphen if not already present
    let slug = `${book_id} - ${english_book_name}`;

    // 2. Remove Unwanted Characters (keep only alphanumeric and spaces)
    slug = slug.replace(/[^a-zA-Z0-9 -]/g, "");

    // 3. Replace Spaces and multiple hyphens with a single Hyphen
    slug = slug.replace(/[\s-]+/g, '-');

    // 4. Convert to Lowercase
    slug = slug.toLowerCase();

    // Limit description to 5 lines
    const separatedDescription = description ? description.split('\n') : '';
    const replacedDescription = separatedDescription ? separatedDescription.slice(0, ).join('\n') : '';

    // Replace newline characters with <br/> tags
    const formattedDescription = replacedDescription ? replacedDescription.replace(/(\n\s*)+/g, ' ') : '';

    // Parse the UTC timestamp into a Date object
    const date = new Date(last_updated);

    // Get the current time and adjust to UTC
    const now = new Date();
    // const nowUTC = new Date(now.getTime() + (now.getTimezoneOffset() * 60000));

    // Function to calculate the time ago
    const getTimeAgo = (pastDate, currentDate) => {
        const msPerMinute = 60 * 1000;
        const msPerHour = msPerMinute * 60;
        const msPerDay = msPerHour * 24;
        const elapsed = currentDate - pastDate;

        if (elapsed < msPerMinute) {
            return Math.round(elapsed / 1000) + ' seconds ago';   
        } else if (elapsed < msPerHour) {
            return Math.round(elapsed / msPerMinute) + ' minutes ago';   
        } else if (elapsed < msPerDay ) {
            return Math.round(elapsed / msPerHour ) + ' hours ago';  
        } else {
            return Math.round(elapsed / msPerDay) + ' days ago';   
        }
    };

    // Calculate the time ago using the custom function
    const timeAgo = getTimeAgo(date, now);

    // Check if the elapsed time is less than a day
    const elapsed = now - date;
    const msPerDay = 24 * 60 * 60 * 1000;

    // Function to estimate reading time
    const estimateReadingTime = (wordCount, averageWPM = 266) => {
        const readingTimeMinutes = wordCount / averageWPM;
        const totalMinutes = Math.floor(readingTimeMinutes);
        
        if (totalMinutes < 60) {
            return `${totalMinutes} min`;
        } else {
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;
            if (minutes === 0) {
                return hours === 1 ? `${hours} hour` : `${hours} hours`;
            } else {
                return hours === 1 ? `${hours} hour ${minutes} min` : `${hours} hours ${minutes} min`;
            }
        }
    };

    const { themeName } = useContext(ThemeContext);

    return (
        <div className={`flex flex-col lg:flex-row justify-center items-center p-2 border-tertiary border-2 gap-5 w-full h-full relative ${has_new_updates && ((themeName == 'dark' || themeName == 'gray') ? 'bg-red-500' : 'bg-red-200')}`}>
            <div className={`flex flex-col lg:flex-row absolute gap-1 top-1 left-1 text-xs text-left text-white font-medium break-words`}>
                <span className={`p-[3px] rounded-full w-fit ${elapsed < 2*msPerDay ? `border-2 border-red-700 bg-red-500` : `hidden`}`}>
                    NEW!
                </span>
                <span className={`p-[3px] rounded-full w-fit ${elapsed < 2*msPerDay ? `border-2 border-emerald-700 bg-emerald-400` : `hidden`}`}>
                    {completion_status === 'Completed' ? 'COMPLETE!' : 'UPDATES!'}
                </span>
            </div>
            <div className="flex flex-col lg:flex-row w-full">
                <div className="flex m-3 justify-center items-center">
                    <a href={"/" + slug} target="_blank">
                        <img src={cover_image} alt={"Cover page of " + english_book_name} loading="lazy" className="object-cover max-w-28 lg:max-w-36 max-h-36 lg:max-h-48" />
                    </a>
                </div>
                <div className="flex flex-col flex-grow justify-center items-center lg:justify-start lg:items-start gap-2">
                    <div className="flex">
                        <a href={"/" + slug} target="_blank">
                            <h2 className="text-xl font-bold text-center lg:text-left break-words">{english_book_name}</h2>
                        </a>
                    </div>
                    
                    <div className="flex flex-row flex-wrap justify-center items-center lg:justify-start gap-2">
                        { like_count > 10 &&
                            <div className='flex flex-row justify-center items-center gap-1 text-xs text-left font-semibold break-words p-1 rounded-2xl w-fit h-6 text-tertiary border border-red-500 bg-red-300'>
                                <LikeIcon size={7} color={'text-black'} /> {like_count} likes
                            </div>
                        }
                        <div className={`flex flex-row justify-center items-center gap-1 text-xs text-left font-semibold break-words p-1 rounded-2xl w-fit h-6 text-tertiary border border-cyan-500 bg-cyan-200`}>
                            <ClockIcon size={8} color={'text-black'} /> {estimateReadingTime(book_word_count)}
                        </div>
                        <div className={`text-xs text-left font-semibold break-words p-1 rounded-2xl w-fit h-6 text-tertiary border ${diversity === 'Danmei/BL' ? 'border-blue-500 bg-blue-200' : 'border-orange-500 bg-orange-200'}`}>
                            {diversity}
                        </div>
                        <div className={`text-xs text-left font-semibold break-words p-1 rounded-2xl w-fit h-6 text-tertiary border ${completion_status === 'Ongoing' ? 'border-green-500 bg-green-200' : 'border-purple-500 bg-purple-200'}`}>
                            {completion_status}
                        </div>
                    </div>
                                        
                    <div className="flex flex-col">
                        <p className="underline">Description:</p>
                        <p 
                            className="mb-4 break-words overflow-hidden" 
                            style={{
                                display: '-webkit-box',
                                WebkitLineClamp: 4,
                                WebkitBoxOrient: 'vertical'
                            }}
                            dangerouslySetInnerHTML={{ __html: formattedDescription }}
                        ></p>
                    </div>
                    <div className="grid grid-cols-6 w-full">
                        <a href={"/" + slug + "/" + latest_chapter} target="_blank" className="col-span-4 items-center text-xs hover:underline truncate block overflow-hidden whitespace-nowrap">
                            Last update: {latest_chapter_title}
                        </a>
                        <p className="col-span-2 justify-self-end items-center text-xs text-center">{timeAgo}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OneBookCard;