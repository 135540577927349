import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import BookChaptersList from './BookChaptersList';
import { SettingsContext } from './SettingsContext';
import { ThemeContext } from './ThemeContext';

const { DecreaseFontSizeIcon, IncreaseFontSizeIcon, DecreasePageSpaceIcon, IncreasePageSpaceIcon, 
        LeftArrowIcon, RightArrowIcon, SettingIcon, ListIcon, BookIcon, CommentIcon } = require('./icons/Icons');

const DesktopToolbar = ({ bookIDSlug, previous_chapter_id, next_chapter_id, chapterList, onThemeChange }) => {
  const [isToolbarVisible, setIsToolbarVisible] = useState(true);
  const navigate = useNavigate();

  const [isSettingsVisible, setIsSettingsVisible] = useState(false);

  const [isChapterListVisible, setIsChapterListVisible] = useState(false);

  const { increaseFontSize, decreaseFontSize, isMaxFontSize, isMinFontSize,
          increasePageSpace, decreasePageSpace, isMaxPageSpace, isMinPageSpace } = useContext(SettingsContext);

  const { setTheme } = useContext(ThemeContext);

  const handleShowChapters = () => {
    setIsChapterListVisible(true);
  };

  const handleCloseChapters = () => {
    setIsChapterListVisible(false);
  };

  const handleOverlayClick = () => {
    handleCloseChapters();
  };

  const handleBoxClick = (e) => {
    // Prevent the overlay's onClick from being triggered
    e.stopPropagation();
  };

  const handleScrollToComment = (e) => {
    e.stopPropagation();
    const element = document.getElementById(`comment-section`);
    if (element) {
        element.scrollIntoView({ behavior: 'auto', block: 'start' });
    }
  }

  return (
    <div className="hidden lg:block w-1/2 bg-red-100 border-2 border-red-400 ">
        {isChapterListVisible && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-5" onMouseDown={handleOverlayClick}>
                <div className="bg-white p-4 rounded-lg shadow-lg w-11/12 max-h-full overflow-auto" onMouseDown={handleBoxClick}>
                  <div className='flex flex-row justify-between items-center mb-3'>
                    <h2 className="text-tertiary text-xl font-bold">Chapter List</h2>
                    <button className="top-2 right-2 text-tertiary" onClick={handleCloseChapters}>
                        X
                    </button>
                  </div>
                  <BookChaptersList bookIDSlug={bookIDSlug} bookChapters={chapterList} fontColor='text-tertiary' />
                </div>
            </div>
        )}
        
        <div className={`transition-transform duration-300 ${isToolbarVisible ? 'transform translate-y-0' : 'transform translate-y-full'}`}>
          <div className="flex justify-around py-2">
              {
                  previous_chapter_id ? (
                    <a href={`/${bookIDSlug}/${previous_chapter_id}`}>
                      <LeftArrowIcon size={20} color={'text-black'} />
                      {/* <img src="/assets/images/common/vec_left.svg" className="w-10 h-10" alt="Previous Chapter" /> */}
                    </a>
                  ) : (
                    <span className="w-10 h-10 opacity-20">
                      <LeftArrowIcon size={20} color={'text-black'} />
                      {/* <img src="/assets/images/common/vec_left.svg" className="w-10 h-10" alt="Previous Chapter" /> */}
                    </span>
                  )
              }
              <button onClick={() => handleShowChapters()}>
                  <span className="w-10 h-10">
                    <ListIcon size={22} color={isChapterListVisible ? 'text-blue-500' : 'text-black'} />
                  </span>
                  {/* <img src="/assets/images/common/vec_list.svg" className="w-10 h-10" alt="Chapter List" /> */}
              </button>
              <button onClick={handleScrollToComment}>
                  <span className="w-10 h-10">
                    <CommentIcon size={22} color={'text-black'} />
                  </span>
              </button>
              <button onClick={() => setIsSettingsVisible(!isSettingsVisible)}>
                  <span className="w-10 h-10">
                    <SettingIcon size={20} color={isSettingsVisible ? 'text-blue-500' : 'text-black'} />
                  </span>
                  {/* <img src="/assets/images/common/vec_setting.svg" className="w-10 h-10" alt="Settings" /> */}
              </button>
              {
                  next_chapter_id ? (
                    <a href={`/${bookIDSlug}/${next_chapter_id}`}>
                      <RightArrowIcon size={20} color={'text-black'} />
                      {/* <img src="/assets/images/common/vec_right.svg" className="w-10 h-10" alt="Next Chapter" /> */}
                    </a>
                  ) : (
                    <span className="w-10 h-10 opacity-20">
                      <RightArrowIcon size={20} color={'text-black'} />
                      {/* <img src="/assets/images/common/vec_right.svg" className="w-10 h-10" alt="Next Chapter" /> */}
                    </span>
                  )
              }
          </div>
          {isSettingsVisible && (
              <div className="flex flex-col justify-around pb-2 border border-red-400 bg-red-200">
                  <div className='grid grid-cols-4 border-b border-red-400 p-1'>
                    <label className='flex justify-start items-center col-span-1 text-tertiary text-right'>Font Size:</label>
                    <div className='grid grid-cols-4 col-span-3'>
                        <button onClick={decreaseFontSize} className={`flex justify-center items-center w-full h-full ${isMinFontSize && 'opacity-30'}`}>
                            <DecreaseFontSizeIcon size={16} color={'text-black'} />
                            {/* <img src="/assets/images/common/vec_decrease_font_size.svg" className={`w-10 h-10 ${isMinFontSize && 'opacity-20'}`} alt="Decrease Font Size" /> */}
                        </button>
                        <button onClick={increaseFontSize} className={`flex justify-center items-center w-full h-full ${isMaxFontSize && 'opacity-30'}`}>
                            <IncreaseFontSizeIcon size={16} color={'text-black'} />
                            {/* <img src="/assets/images/common/vec_increase_font_size.svg" className={`w-10 h-10 ${isMaxFontSize && 'opacity-20'}`} alt="Increase Font Size" /> */}
                        </button>
                    </div>
                  </div>

                  {/* <div className='grid grid-cols-4 border-b border-red-400 p-1'>
                    <label className='flex justify-start items-center col-span-1 text-tertiary text-right'>Page Space:</label>
                    <div className='grid grid-cols-4 col-span-3'>
                        <button onClick={decreasePageSpace} className={`flex justify-center items-center w-full h-full ${isMinPageSpace && 'opacity-30'}`}>
                            <DecreasePageSpaceIcon size={16} color={'text-black'} />
                        </button>
                        <button onClick={increasePageSpace} className={`flex justify-center items-center w-full h-full ${isMaxPageSpace && 'opacity-30'}`}>
                            <IncreasePageSpaceIcon size={16} color={'text-black'} />
                        </button>
                    </div>
                  </div> */}
                  
                  <div className='grid grid-cols-4 p-1'>
                    <label className='flex justify-start items-center col-span-1 text-tertiary text-right'>Theme:</label>
                    <div className='grid grid-cols-4 col-span-3 gap-y-2 place-items-center'>
                        <button onClick={() => setTheme('crimson')} className='w-fit'>
                            <span className="block w-10 h-10 bg-primary border-2 border-black">
                              <label className='flex justify-center items-center text-center text-tertiary text-3xl cursor-pointer'>T</label>
                            </span>
                        </button>
                        <button onClick={() => setTheme('jade')} className='w-fit'>
                            <span className="block w-10 h-10 bg-green-100 border-2 border-black">
                              <label className='flex justify-center items-center text-center text-tertiary text-3xl cursor-pointer'>T</label>
                            </span>
                        </button>
                        <button onClick={() => setTheme('azure')} className='w-fit'>
                            <span className="block w-10 h-10 bg-blue-100 border-2 border-black">
                              <label className='flex justify-center items-center text-center text-tertiary text-3xl cursor-pointer'>T</label>
                            </span>
                        </button>
                        <button onClick={() => setTheme('light')} className='w-fit'>
                            <span className="block w-10 h-10 bg-white border-2 border-black">
                              <label className='flex justify-center items-center text-center text-black text-3xl cursor-pointer'>T</label>
                            </span>
                        </button>
                        <button onClick={() => setTheme('dark')} className='w-fit'>
                            <span className="block w-10 h-10 bg-black border-2 border-black">
                              <label className='flex justify-center items-center text-center text-white text-3xl cursor-pointer'>T</label>
                            </span>
                        </button>
                        <button onClick={() => setTheme('gray')} className='w-fit'>
                            <span className="block w-10 h-10 bg-gray-700 border-2 border-black">
                              <label className='flex justify-center items-center text-center text-white text-3xl cursor-pointer'>T</label>
                            </span>
                        </button>
                    </div>
                  </div>
              </div>
          )}
        </div>
    </div>
  );
};

export default DesktopToolbar;