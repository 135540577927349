import React from "react";
import { Helmet } from 'react-helmet';
import RegisterForm from "../../components/users/RegisterForm";

function LoginPage () {
    return (
        <div className="flex justify-center items-center">
            <Helmet>
                <meta name="register" content="User registration" />
                <title>Register</title>
            </Helmet>
            <RegisterForm />
        </div>
    );
}

export default LoginPage;