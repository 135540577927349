import React, { useState, useContext } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [honeypot, setHoneypot] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/user/forgot-password`, { email, honeypot });
      setMessage(response.data.message);
    } catch (error) {
      setMessage('An error occurred. Please try again.');
    } finally {
      setTimeout(() => setIsSubmitting(false), 1000); // Re-enable the button after 1 second
    }
  };

  return (
    <div className="flex justify-center items-center">
      <Helmet>
          <meta name="forgot-password" content="Forgot password" />
          <title>Forgot Password</title>
      </Helmet>
      <form onSubmit={handleSubmit} className="flex flex-col justify-center items-center w-full lg:w-1/2 p-4 rounded-md">
        <h2 className="text-2xl mb-4">Forgot Password</h2>
        {message && <p className="text-red-500 mb-4 text-center">{message}</p>}
        
        <div className="w-full mb-4">
          <div className="grid grid-cols-4 gap-2 mb-4 items-center">
            <label className='col-span-1 text-center'>Email:</label>
            <input
              className='col-span-3 p-2 border border-tertiary text-tertiary rounded-md'
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {/*honeypot*/}
            <input
              className="hidden text-tertiary"
              type="text"
              name="phone_number"
              value={honeypot}
              onChange={(e) => setHoneypot(e.target.value)}
              tabIndex="-1"
              autoComplete="off"
            />
          </div>
        </div>
        
        <button type="submit" disabled={isSubmitting} className="bg-red-400 text-white py-2 px-4 rounded-md mb-2 hover:bg-tertiary transition duration-300">Reset Password</button>
      </form>
    </div>
  );
};

export default ForgotPassword;