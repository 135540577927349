import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import Spoiler from './Spoiler';
import '../styles/markdown.css';

const CustomMarkdown = ({ markdown }) => {
  // Replace \n with <br />
  let formattedMarkdown = markdown ? markdown.replace(/\n/g, '<br/>') : '';

  // Replace %spoiler text% with <s>spoiler text</s>
  formattedMarkdown = formattedMarkdown ? formattedMarkdown.replace(/%([^%]+)%/g, '<s>$1</s>') : '';

  return (
    <div className='max-w-full break-words'>
      <ReactMarkdown
        rehypePlugins={[rehypeRaw]}
        components={{
          s: ({ node, ...props }) => <Spoiler {...props} />,
        }}
      >
        {formattedMarkdown}
      </ReactMarkdown>
    </div>
  );
};

export default CustomMarkdown;