import React, { useContext } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const ProtectedRoute = ({ element: Component }) => {
  const { user, loading } = useContext(AuthContext);

  if (loading) {
    return null; // Show a loading indicator while checking authentication
  }

  return user ? <Component /> : <Navigate to="/user/login" />;
};

export default ProtectedRoute;