import React from "react";
import { Helmet } from 'react-helmet';

import Info from "../components/Info";
import Sidebar from "../components/Sidebar";
import Hero from "../components/Hero";

function DMCA () {
    const title = "DMCA Policy";
    const content = `
        If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted material has been copied in a way that constitutes copyright infringement, please submit your claim via email to our Copyright Agent, including the following information:
        <br /><br />
        1. A physical or electronic signature of the copyright owner or a person authorized to act on their behalf;
        <br />
        2. Identification of the copyrighted work claimed to have been infringed;
        <br />
        3. Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material;
        <br />
        4. Your contact information, including your address, telephone number, and an email address;
        <br />
        5. A statement by you that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law;
        <br />
        6. A statement that the information in the notification is accurate, and, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.
        <br />
        Crimson Translations' Copyright Agent for notice of alleged copyright infringement can be reached at:
        <br /><br />
        Email: crimsontranslations@gmail.com
    `;

    return (
        <div className="flex-col">
            <div className='flex flex-col lg:flex-row justify-center items-center lg:items-start m-5 gap-10 max-w-full'>
                <Helmet>
                    <meta name="description" content={content} />
                    <title>DMCA Policy</title>
                </Helmet>
                <Info title={title} content={content} />

                <div className='justify-start items-start lg:max-w-xs'>
                    <Sidebar />
                </div>
            </div>
        </div>
    );
}

export default DMCA;