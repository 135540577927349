import React, { useState } from "react";

function createARadioCategorySearch (category, options, checkedValue, onChangeHandler) {
    return (
        <div className="category grid grid-cols-3 lg:grid-cols-5 border-b-2 p-1 border-tertiary w-full">
            <h3 className="col-span-1 mb-2 font-bold text-lg">{category}</h3>
            <div className="col-span-2 lg:col-span-4 grid grid-cols-2 lg:grid-cols-4 gap-2">
                {options.map((option, index) => (
                    <label key={index} className="flex items-center space-x-2">
                        <input
                            type="radio"
                            name={category}
                            value={option.value}
                            className="form-checkbox h-3 w-3"
                            checked={checkedValue === option.value}
                            onChange={(e) => onChangeHandler(e.target.value)}
                        />
                        <span className="text-sm">{option.label}</span>
                    </label>
                ))}
            </div>
        </div>
    );
}

function createACheckboxCategorySearch (category, options, checkedValue, onChangeHandler) {
    return (
        <div className="category grid grid-cols-3 lg:grid-cols-5 border-b-2 p-1 border-tertiary w-full">
            <h3 className="col-span-1 mb-2 font-bold text-lg">{category}</h3>
            <div className="col-span-2 lg:col-span-4 grid grid-cols-2 lg:grid-cols-4 gap-2">
                {options.map((option, index) => (
                    <label key={index} className="flex items-center space-x-2">
                        <input
                            type="checkbox"
                            name={category}
                            value={option.value}
                            className="form-radio h-3 w-3"
                            checked={checkedValue.includes(option.value)}
                            onChange={() => onChangeHandler(option.value)}
                        />
                        <span className="text-sm">{option.label}</span>
                    </label>
                ))}
            </div>
        </div>
    );
}

function createWordCountSearch (category, minOptions, maxOptions, minValue, maxValue, onMinChangeHandler, onMaxChangeHandler) {
    return (
        <div className="category grid grid-cols-3 lg:grid-cols-5 border-b-2 p-1 border-tertiary w-full">
            <h3 className="col-span-1 mb-2 font-bold text-lg">{category}</h3>
            <div className="col-span-2 lg:col-span-4 grid grid-cols-2 lg:grid-cols-4 gap-2">
                <label className="flex items-center space-x-2">
                    <select
                        value={minValue}
                        onChange={(e) => onMinChangeHandler(e.target.value)}
                        className="form-select h-6 w-full border-2 border-tertiary text-tertiary"
                    >
                        {minOptions.map((option, index) => (
                            <option key={index} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                    <span className="text-sm">to</span>
                </label>
                <label className="flex items-center space-x-2">
                    <select
                        value={maxValue}
                        onChange={(e) => onMaxChangeHandler(e.target.value)}
                        className="form-select h-6 w-full border-2 border-tertiary text-tertiary"
                    >
                        {maxOptions.map((option, index) => (
                            <option key={index} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </label>
            </div>
        </div>
    );
}

function SearchBox ({ onSearchData, searchBookMessage, isSubmitting }) {
    const [checkedDiversity, setCheckedDiversity] = useState("All");
    const [checkedLanguage, setCheckedLanguage] = useState("All");
    const [checkedStatus, setCheckedStatus] = useState("All");
    const [checkedEnding, setCheckedEnding] = useState("All");
    
    const [minWordCount, setMinWordCount] = useState("Any");
    const [maxWordCount, setMaxWordCount] = useState("Any");

    const [checkedSettings, setCheckedSettings] = useState([]);
    const [checkedGenres, setCheckedGenres] = useState([]);
    const [checkedTags, setCheckedTags] = useState([]);
  
    const handleDiversityChange = (value) => {
        setCheckedDiversity(value);
    };

    const handlLanguageChange = (value) => {
        setCheckedLanguage(value);
    };

    const handlStatusChange = (value) => {
        setCheckedStatus(value);
    };

    const handleEndingChange = (value) => {
        setCheckedEnding(value);
    };

    const handleMinWordCountChange = (value) => {
        setMinWordCount(value);
    };
    
    const handleMaxWordCountChange = (value) => {
        setMaxWordCount(value);
    };

    const handleSettingChange = (setting) => {
        setCheckedSettings(prev => {
            if (prev.includes(setting)) {
                return prev.filter(t => t !== setting); // Remove setting from array
            } else {
                return [...prev, setting]; // Add setting to array
            }
        });
    };

    const handleGenreChange = (genre) => {
        setCheckedGenres(prev => {
            if (prev.includes(genre)) {
                return prev.filter(t => t !== genre); // Remove genre from array
            } else {
                return [...prev, genre]; // Add genre to array
            }
        });
    };

    const handleTagChange = (tag) => {
        setCheckedTags(prev => {
            if (prev.includes(tag)) {
                return prev.filter(t => t !== tag); // Remove tag from array
            } else {
                return [...prev, tag]; // Add tag to array
            }
        });
    };
    
    const diversityOptions = [
        { value: 'All', label: 'All' },
        { value: 'Danmei/BL', label: 'Danmei/BL' },
        { value: 'Non-BL', label: 'Non-BL' },
    ];

    const languageOptions = [
        { value: 'All', label: 'All' },
        { value: 'Chinese', label: 'Chinese' },
        { value: 'Japanese', label: 'Japanese' },
    ];

    const completionStatusOptions = [
        { value: 'All', label: 'All' },
        { value: 'Ongoing', label: 'Ongoing' },
        { value: 'Completed', label: 'Completed' },
    ];

    const endingOptions = [
        { value: 'All', label: 'All' },
        { value: 'Happy Ending', label: 'Happy Ending' },
        { value: 'Sad Ending', label: 'Sad Ending' },
        { value: 'Complex Ending', label: 'Complex Ending' },
    ];

    const allMinWordCountOptions = [
        { value: 'Any', label: 'Any' },
        { value: '5001', label: '5001 words' },
        { value: '10001', label: '10001 words' },
        { value: '20001', label: '20001 words' },
        { value: '50001', label: '50001 words' },
    ];
    
    const allMaxWordCountOptions = [
        { value: 'Any', label: 'Any' },
        { value: '10000', label: '10000 words' },
        { value: '20000', label: '20000 words' },
        { value: '50000', label: '50000 words' },
    ];

    const filteredMinOptions = allMinWordCountOptions.filter(option => option.value === 'Any' || parseInt(option.value) < (maxWordCount === 'Any' ? Infinity : parseInt(maxWordCount)));
    const filteredMaxOptions = allMaxWordCountOptions.filter(option => option.value === 'Any' || parseInt(option.value) > (minWordCount === 'Any' ? -Infinity : parseInt(minWordCount)));

    const settingOptions = [
        { value: 'Modern', label: 'Modern' },
        { value: 'Medieval', label: 'Medieval' },
        { value: 'Ancient Chinese', label: 'Ancient Chinese' },
        { value: 'Futuristic', label: 'Futuristic' },
        { value: 'Apocalypse', label: 'Apocalypse' },
    ];

    const genreOptions = [
        { value: 'Romance', label: 'Romance' },
        { value: 'Science Fiction', label: 'Science Fiction' },
        { value: 'Mystery', label: 'Mystery' },
        { value: 'Horror', label: 'Horror' },
        { value: 'Comedy', label: 'Comedy' },
        { value: 'Drama', label: 'Drama' },
        { value: 'Fantasy', label: 'Fantasy' },
        { value: 'Supernatural', label: 'Supernatural' },
        { value: 'Tragedy', label: 'Tragedy' },
        { value: 'Action', label: 'Action' },
        { value: 'Adventure', label: 'Adventure' },
        { value: 'Slice of Life', label: 'Slice of Life' },
    ];

    const tagOptions = [
        { value: 'Heartbreaking', label: 'Heartbreaking' },
        { value: 'Heartwarming', label: 'Heartwarming' },
        { value: 'Lighthearted', label: 'Lighthearted' },
        { value: 'Revenge', label: 'Revenge' },
        { value: 'Scheming', label: 'Scheming' },
        { value: 'Political Battle', label: 'Political Battle' },
        { value: 'Face Slapping', label: 'Face Slapping' },
        { value: 'Red Flag', label: 'Red Flag' },
        { value: 'Fake Death', label: 'Fake Death' },
        { value: 'Dog Blood', label: 'Dog Blood' },
        { value: 'Redemption', label: 'Redemption' },
        { value: 'Entertainment Industry', label: 'Entertainment Industry' },
        { value: 'System', label: 'System' },
        { value: 'Omegaverse', label: 'Omegaverse' },
        { value: 'Reincarnation', label: 'Reincarnation' },
        { value: 'Transmigration', label: 'Transmigration' },
        { value: 'Cultivation', label: 'Cultivation' },
        { value: 'Wuxia', label: 'Wuxia' },
        { value: 'Gaming', label: 'Gaming' },
        { value: 'Childhood Friend', label: 'Childhood Friend' },
        { value: 'Secret Crush', label: 'Secret Crush' },
        { value: 'Enemy Turns Lover', label: 'Enemy Turns Lover' },
        { value: 'Love-chasing Crematorium', label: 'Love-chasing Crematorium' },
        { value: 'Contract Marriage', label: 'Contract Marriage' },
        { value: 'Marriage Before Love', label: 'Marriage Before Love' },
        { value: 'Sugar Daddy', label: 'Sugar Daddy' },
        { value: 'Stand In', label: 'Stand In' },
        { value: 'White Moonlight', label: 'White Moonlight' },
        { value: 'Mentally Unstable', label: 'Mentally Unstable' },
        { value: 'Memory Loss', label: 'Memory Loss' },
        { value: 'Family Conflict', label: 'Family Conflict' },
        { value: 'Green Tea', label: 'Green Tea' },
        { value: 'Sweet', label: 'Sweet' },
        { value: 'Bittersweet', label: 'Bittersweet' },
        { value: 'Campus', label: 'Campus' },
        { value: 'Office', label: 'Office' },
        { value: 'Sentinel and Guide', label: 'Sentinel and Guide' },
        { value: 'Interstellar', label: 'Interstellar' },
        { value: 'Power Couple', label: 'Power Couple' },
        { value: 'Secret Identity', label: 'Secret Identity' },
        { value: 'Obsessive', label: 'Obsessive' },
        { value: 'Yandere', label: 'Yandere' },
        { value: 'Childcare', label: 'Childcare' },
        { value: 'Royalty', label: 'Royalty' },
        { value: 'Long Separations', label: 'Long Separations' },
        { value: 'Human-Nonhuman Relationship', label: 'Human-Nonhuman Relationship' },
        { value: 'Wealthy Family', label: 'Wealthy Family' },
        { value: 'Cute', label: 'Cute' },
    ];

    const [searchTerm, setSearchTerm] = useState('');
    const [sortingCriteria, setSortingCriteria] = useState('newest');

    // Function to handle changes in the search input
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    // Function to handle the search submission
    const handleSearchSubmit = (event) => {
        event.preventDefault(); // Prevent the form from causing a page reload
        
        const formData = {
            diversity: checkedDiversity,
            original_language: checkedLanguage,
            completion_status: checkedStatus,
            ending: checkedEnding,
            min_word_count: minWordCount,
            max_word_count: maxWordCount,
            setting: checkedSettings,
            genre: checkedGenres,
            tag: checkedTags,
            book_name: searchTerm,
            sort_by: sortingCriteria
        };
        
        onSearchData(formData);
    };

    return (
        <div className="container flex flex-col justify-center items-center m-1 max-w-fit border-2 border-tertiary">
            {createARadioCategorySearch("Diversity", diversityOptions, checkedDiversity, handleDiversityChange)}
            {createARadioCategorySearch("Original Language", languageOptions, checkedLanguage, handlLanguageChange)}
            {createARadioCategorySearch("Completion Status", completionStatusOptions, checkedStatus, handlStatusChange)}
            {createARadioCategorySearch("Ending", endingOptions, checkedEnding, handleEndingChange)}
            {createWordCountSearch("Word Count", filteredMinOptions, filteredMaxOptions, minWordCount, maxWordCount, handleMinWordCountChange, handleMaxWordCountChange)}
            {createACheckboxCategorySearch("Setting", settingOptions, checkedSettings, handleSettingChange)}
            {createACheckboxCategorySearch("Genre", genreOptions, checkedGenres, handleGenreChange)}
            {createACheckboxCategorySearch("Tag", tagOptions, checkedTags, handleTagChange)}
      
            {/* <form className="flex flex-col justify-center items-center m-2 gap-2" onSubmit={handleSearchSubmit}>
                <div className="flex justify-center items-center gap-5">
                    <span className="mb-2 font-bold text-lg">Search: </span>
                    <input
                        type="text"
                        className="flex-grow max-w-fit p-2 border-2 border-tertiary"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </div>

                <div className="flex justify-center items-center">
                    <button type="submit" className="max-w-fit p-2 border-2 border-tertiary hover:text-secondary font-bold">
                            Search
                    </button>
                </div>
            </form> */}
            
            <div className="flex flex-col justify-center items-center m-2 gap-2">
                <div className="flex justify-center items-center gap-5">
                    <span className="mb-2 font-bold text-lg">Search: </span>
                    <input
                        type="text"
                        className={`flex-grow max-w-fit p-2 border-2 text-tertiary ${searchBookMessage ? 'border-red-500' : 'border-tertiary'}`}
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </div>

                {searchBookMessage && <p className="text-red-500">{searchBookMessage}</p>}

                <div className="flex items-center">
                    <label className="mr-2">Sort by</label>
                    <select 
                        value={sortingCriteria} 
                        onChange={(e) => setSortingCriteria(e.target.value)} 
                        className="border p-2 rounded-md text-tertiary"
                    >
                        <option value="likes">Most Liked</option>
                        <option value="newest">Newest</option>
                        <option value="oldest">Oldest</option>
                    </select>
                </div>

                <div className="flex justify-center items-center">
                    <button type="submit" disabled={isSubmitting} className='max-w-fit p-2 border-2 hover:text-secondary font-bold border-tertiary' onClick={handleSearchSubmit}>
                            Search
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SearchBox;