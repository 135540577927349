import React, { useEffect, useState } from 'react';

const InArticle = () => {
  const [showAds, setShowAds] = useState(false);

  useEffect(() => {
    // Set up a timeout to check and set the `showAds` state
    const timer = setTimeout(() => {
      const shouldShowAds = true;
        // window.location.hostname === 'crimsontranslations.com' || window.location.hostname === 'www.crimsontranslations.com';
      setShowAds(shouldShowAds);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (showAds) {
      const initializeAds = () => {
        const adsbygoogle = window.adsbygoogle || [];
        const adElements = document.querySelectorAll('ins.adsbygoogle');

        adElements.forEach((adElement) => {
          if (!adElement.getAttribute('data-adsbygoogle-status')) {
            adsbygoogle.push({});
          }
        });
      };

      const existingScript = document.querySelector('script[src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"]');

      if (!existingScript) {
        // Load Google AdSense script
        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
        script.setAttribute('data-ad-client', 'ca-pub-5619677276309160');
        script.crossOrigin = 'anonymous';
        script.onload = initializeAds;
        document.head.appendChild(script);

        return () => {
          document.head.removeChild(script);
        };
      } else {
        initializeAds();
      }
    }
  }, [showAds]);

  if (!showAds) {
    return null; // Don't render the ad container if showAds is false
  }

  return (
    <div className='m-5 min-w-[250px] min-h-[50px] max-w-full overflow-x-visible'>
      {/* In-Article AD */}
      <ins className="adsbygoogle"
        style={{ display: 'block', textAlign: 'center' }}
        data-ad-layout="in-article"
        data-ad-format="fluid"
        data-ad-client="ca-pub-5619677276309160"
        data-ad-slot="4961997033"></ins>
    </div>
  );
};

export default InArticle;