import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./users/AuthContext";
import axios from "axios";
const { ProfileIcon, NotificationIcon } = require('./icons/Icons');

function Header() {
    const { user, isAuthenticated, logout, refreshToken } = useContext(AuthContext);
    const navigate = useNavigate();
    const [unreadRepliesCount, setUnreadRepliesCount] = useState(0);
    const [uncheckedUpdatesCount, setUncheckedUpdatesCount] = useState(0);
    const [userNavigateBarVisibility, setUserNavigateBarVisibility] = useState(user);

    useEffect(() => {
        const fetchUnreadReplies = async () => {
            if (user) {
                await refreshToken();
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/replies/unread-count`);
                    setUnreadRepliesCount(response.data.unreadCount);
                } catch (error) {
                    console.error('Error fetching unread replies:', error);
                }
            }
        };
        
        const fetchUncheckedUpdatesCount = async () => {
            if (user) {
                await refreshToken();
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/liked-story/unchecked-updates-count`);
                    setUncheckedUpdatesCount(response.data.uncheckedUpdatesCount);
                } catch (error) {
                    console.error('Error fetching new books updates:', error);
                }
            }
        };

        fetchUnreadReplies();
        fetchUncheckedUpdatesCount();
    }, [user, refreshToken]);

    const userNavigateBar = () => {
        return (
            <div className="flex flex-col p-4">
                <a
                    href="/user/logout" 
                    onClick={handleLogout} 
                    className="text-tertiary hover:bg-red-400 hover:text-white p-1 rounded-md"
                >
                    Log Out
                </a>
                <a 
                    href="/user/profile"
                    className="text-tertiary hover:bg-red-400 hover:text-white p-1 rounded-md"
                >
                    Profile
                </a>
                <a 
                    href="/user/liked-story"
                    className="text-tertiary hover:bg-red-400 hover:text-white p-1 rounded-md"
                >
                    Liked Story
                    {uncheckedUpdatesCount > 0 && (
                        <span className="inline-flex items-center justify-center px-2 py-1 ml-2 text-xs font-bold leading-none text-red-100 bg-red-700 rounded-full">
                            {uncheckedUpdatesCount > 99 ? '99+' : uncheckedUpdatesCount}
                        </span>
                    )}
                </a>
                <a 
                    href="/user/reply-notification"
                    className="text-tertiary hover:bg-red-400 hover:text-white p-1 rounded-md relative"
                >
                    Reply Notification
                    {unreadRepliesCount > 0 && (
                        <span className="inline-flex items-center justify-center px-2 py-1 ml-2 text-xs font-bold leading-none text-red-100 bg-red-700 rounded-full">
                            {unreadRepliesCount > 99 ? '99+' : unreadRepliesCount}
                        </span>
                    )}
                </a>
            </div>
        );
    };

    const handleLogout = (e) => {
        e.preventDefault();
        logout();
        if (window.location.pathname === '/') {
            window.location.reload(); // Refresh the page if already on the home page
        } else {
            navigate('/'); // Redirect to home page if not already there
        }
        setUserNavigateBarVisibility(false);
    };

    return (
        <header className="bg-secondary">
            <nav className="flex items-center justify-between max-w-full px-1 relative">
                <div className="flex flex-1 justify-center items-center gap-x-14 lg:gap-x-28 text-2xl w-full">    
                    <a href="/" className="text-tertiary hover:text-primary font-bold">Home</a>   
                    <a href="/searchbook" className="text-tertiary hover:text-primary font-bold">Search</a>
                </div>
                <div className="flex flex-col items-end">
                    {
                        user ? (
                            <button
                                onClick={() => setUserNavigateBarVisibility(!userNavigateBarVisibility)}
                                className={`flex flex-row justify-center items-center absolute top-0 right-0 mt-0.5 mr-1`}
                            >
                                {
                                    (unreadRepliesCount > 0 || uncheckedUpdatesCount > 0) && 
                                    <NotificationIcon size={10} color={`${(unreadRepliesCount > 0 || uncheckedUpdatesCount > 0) ? 'text-blue-500' : 'text-tertiary'}`} /> 
                                }
                                <ProfileIcon size={15} color={`${(unreadRepliesCount > 0 || uncheckedUpdatesCount > 0) ? 'text-blue-500' : 'text-tertiary'}`} />
                            </button>
                        ) : (
                            <a 
                                href="/user/login"
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="absolute top-0 right-0 mt-0.5 mr-1"
                            >
                                <ProfileIcon size={15} color='text-tertiary' />
                            </a>
                        )
                    }
                    {userNavigateBarVisibility && 
                    <div className="absolute right-0 mt-4 bg-white border shadow-lg">
                        {userNavigateBar()}
                    </div>
                    }
                </div>
            </nav>
        </header>
    );
}

export default Header;