/*
CommentSection.js

Post Comment:
Take book_id and chapter_id from parent component

UseState: parentCommentID, default null
UseState: commentContent, default ''
UseState: commentArrangementCriteria, default 'likes'
UseState: commentReload, default 'false'

Check authenticated checking the existance of "user" in AuthContext
Refresh token using "refreshToken" in AuthContext

An input, below it is a button named "Post Comment".
If the user is not authenticated, the whole posting comments section will be grayed out and unclickable, and show a message "You must log in to comment." on the section.
Clicking the button will call /${process.env.REACT_APP_BASE_URL}/comment/post-comments, and pass book_id, chapter_id, parent_comment_id, comment_content.
The comment content will be set everytime the content in the input changed.
Set the commentReload to 'true'.


Show comments:
A label "Sort by", a dropdown menu with value likes (default), newest, oldest. This will set the criteria.

If the user is not logged in, call /${process.env.REACT_APP_BASE_URL}/comment/show-comments, and pass book_id, chapter_id = 0, criteria. The response will contain "comments".
If the user is logged in, call /${process.env.REACT_APP_BASE_URL}/comment/show-comments-with-like-comments, and pass book_id, chapter_id = 0, criteria. The response will contain "comments" and "liked_comments".
Use the react query to fetch the data, when the commentReload become 'true', it will refetch the data and set the commentReload to 'false'. Then rerender the components.

Pass the comments one by one, all liked_comments (optional), setParentCommentID, and setCommentReload to a component named OneComment.js
When the "parent_comment_id" of a comment from comments is null, it will be at left most.
When the "parent_comment_id" of a comment from comments is not null, it will be put under their parent comment and at slightly righter than their parent comment.
The comment will only have 2 layers. Meaning that when someone post a comment without "parent_comment_id", it will be at the left most. When someone post/reply a comment with "parent_comment_id", they will be at layer 2 (slightly righter than layer 1)
*/

import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../users/AuthContext';
import { ThemeContext } from '../ThemeContext';
import { useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import OneComment from './OneComment';
import commentValidation from './commentValidation';

const CommentSection = ({ book_id, chapter_id }) => {
  const { user, refreshToken } = useContext(AuthContext);
  const [parentCommentID, setParentCommentID] = useState(null);
  const [commentContent, setCommentContent] = useState('');
  const [commentArrangementCriteria, setCommentArrangementCriteria] = useState('likes');
  const [commentReload, setCommentReload] = useState(false);
  const [postCommentMessage, setPostCommentMessage] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [honeypot, setHoneypot] = useState('');

  const { themeName } = useContext(ThemeContext);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (commentReload) {
      queryClient.invalidateQueries(['comments', book_id, chapter_id, commentArrangementCriteria]);
      setCommentReload(false);
    }
  }, [commentReload, commentArrangementCriteria, queryClient]);

  const fetchComments = async () => {
    if (user) {
      await refreshToken();
    }

    const endpoint = user 
      ? `${process.env.REACT_APP_BASE_URL}/api/comment/show-comments-with-like-comments` 
      : `${process.env.REACT_APP_BASE_URL}/api/comment/show-comments`;
    const response = await axios.get(endpoint, {
      params: {
        book_id,
        chapter_id,
        criteria: commentArrangementCriteria,
      }
    });
    
    return response.data;
  };

//   const { data, error, isLoading, isError } = useQuery(['comments', { book_id: book_id, chapter_id: chapter_id }, commentArrangementCriteria], fetchComments, {
//     refetchOnWindowFocus: false,
//     staleTime: 300000, // 5 minutes in milliseconds
//     cacheTime: 600000, // 10 minutes in milliseconds
//   });

  const { data, error, isLoading } = useQuery(['comments', book_id, chapter_id, commentArrangementCriteria], fetchComments, {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!isLoading) {
      const hash = window.location.hash;
      if (hash) {
        const element = document.getElementById(hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: 'auto', block: 'start' });
          const elementColor = ((themeName == 'dark' || themeName == 'gray') ? 'bg-red-500' : 'bg-red-200')
          element.classList.add(elementColor, 'transition', 'duration-1000');
          
          // Optionally remove the highlight after a delay
          setTimeout(() => {
            element.classList.remove(elementColor);
          }, 3000); // Adjust the duration as needed
        }
      }
    }
  }, [isLoading]);

  const postComment = async () => {
    setIsSubmitting(true);
    if (!user) {
      alert('You must log in to comment.');
      setTimeout(() => setIsSubmitting(false), 1000); // Re-enable the button after 1 second
      return;
    }

    const passValidation = commentValidation(commentContent, setPostCommentMessage);
    if (!passValidation) {
      setTimeout(() => setIsSubmitting(false), 1000); // Re-enable the button after 1 second
      return;
    }

    try {
      const currentTime = new Date().getTime();
      const lastCommentTime = localStorage.getItem('lastCommentTime');

      if (lastCommentTime && (currentTime - lastCommentTime) < 30000) {
        const remainingTime = Math.ceil((30000 - (currentTime - lastCommentTime)) / 1000);
        setPostCommentMessage(`Please wait for ${remainingTime} seconds before commenting again.`);
        setTimeout(() => setIsSubmitting(false), 1000); // Re-enable the button after 1 second
        return;
      }

      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/comment/post-comments`, {
                book_id,
                chapter_id,
                parent_comment_id: parentCommentID,
                comment_content: commentContent,
                honeypot: honeypot,
              });

      if (res.data.success == false) {
        setPostCommentMessage(res.data.message);
      } else {
        setCommentContent('');
        setParentCommentID(null);
        setCommentReload(true);
        setPostCommentMessage('');
        localStorage.setItem('lastCommentTime', currentTime); // Save the last comment time in localStorage

        // Get the comment_id from the response and redirect to the specific comment
        const commentId = res.data.comment_id;

        // Wait for the comments to reload before scrolling
        setTimeout(() => {
            // Apply scroll and highlight logic
            const element = document.getElementById(`${commentId}`);
            if (element) {
                element.scrollIntoView({ behavior: 'auto', block: 'start' });
                const elementColor = ((themeName == 'dark' || themeName == 'gray') ? 'bg-red-500' : 'bg-red-200')
                element.classList.add(elementColor, 'transition', 'duration-1000');
                // Optionally remove the highlight after a delay
                setTimeout(() => {
                    element.classList.remove(elementColor);
                }, 2000); // Adjust the duration as needed
            }
        }, 500); // Adjust the delay as needed to ensure comments are loaded
      }
    } catch (error) {
      console.error('Error posting comment:', error);
      // Display error message to the user
    } finally {
      setTimeout(() => setIsSubmitting(false), 1000); // Re-enable the button after 1 second
    }
  };

  // Organize comments based on parent_comment_id
  const organizeComments = (comments) => {
    const commentMap = new Map();
    const parentUsernames = new Map();
    const parentDeleted = new Map();
    const parentReportCount = new Map();

    comments.forEach(comment => {
      commentMap.set(comment.comment_id, { ...comment, children: [] });
      parentUsernames.set(comment.comment_id, comment.username); // store usernames
      parentDeleted.set(comment.comment_id, comment.deleted_comment); // store if the parent is deleted
      parentReportCount.set(comment.comment_id, comment.report_count); // store report count
    });

    commentMap.forEach(comment => {
      if (comment.parent_comment_id !== null) {
        const parent = commentMap.get(comment.parent_comment_id);
        if (parent) {
          comment.parent_username = parentUsernames.get(comment.parent_comment_id); // set parent_username
          comment.parent_deleted = parentDeleted.get(comment.parent_comment_id); // set parent_deleted
          comment.parent_report_count = parentReportCount.get(comment.parent_comment_id); // set parent_report_count
          parent.children.push(comment);
        }
      }
    });
    return Array.from(commentMap.values()).filter(comment => comment.parent_comment_id === null);
  };

  const organizedComments = data && data.comments ? organizeComments(data.comments) : [];

  return (
    <div id='comment-section' className='w-full p-2'>
      <div className="flex flex-col justify-center items-center mb-4">
        <h2 className='text-3xl font-bold mb-4'>Comments</h2>
        <textarea
          value={commentContent}
          onChange={(e) => setCommentContent(e.target.value)}
          disabled={!user || !user.email_verified}
          className={`border p-2 w-10/12 h-24 rounded-md text-tertiary ${postCommentMessage ? 'border-red-500' : 'border-black'}`}
          style={{ opacity: user ? 1 : 0.5 }}
          placeholder={
            (!user || !user.email_verified) 
            ? 'Please log in and verify your email to comment.' 
            : 'Tips:\n1. Be nice to each other.\n2. Register on Gravatar for a profile picture.\n3. Markdown styling rule applies. Wrap your spoiler in %(your spoiler here)%.'
          }
        />
        {!user && <p className='text-red-500'>You must <a href="/user/login" target="_blank" rel="noopener noreferrer" className='text-blue-400 underline hover:no-underline'>log in</a> to comment.</p>}
        {postCommentMessage && <p className='text-red-500'>{postCommentMessage}</p>}

        {/*honeypot*/}
        <input
            className="hidden text-tertiary"
            type="text"
            name="phone_number"
            value={honeypot}
            onChange={(e) => setHoneypot(e.target.value)}
            tabIndex="-1"
            autoComplete="off"
        />

        <div className="flex justify-center items-center mt-2">
          <button 
            onClick={postComment} 
            disabled={!user || !user.email_verified || isSubmitting} 
            className="bg-red-400 text-white py-2 px-4 rounded-md mb-2 hover:bg-tertiary transition duration-300 disabled:opacity-50"
          >
            Post Comment
          </button>
          {parentCommentID && (
            <button 
              onClick={() => setParentCommentID(null)} 
              className="ml-2 bg-gray-500 text-white py-2 px-4 rounded-md"
            >
              Cancel
            </button>
          )}
        </div>
      </div>

      {isLoading && <p className='text-center'>Loading comments...</p>}
      {error && <p className='text-center'>Error loading comments.</p>}
      <div className="flex flex-col lg:flex-row justify-between items-center mb-4">
        <p className="text-xl font-semibold">Showing {!isLoading && data.comments ? data.comments.length : 0} Comments</p>
        <div className="flex items-center">
          <label className="mr-2">Sort by</label>
          <select 
            value={commentArrangementCriteria} 
            onChange={(e) => setCommentArrangementCriteria(e.target.value)} 
            className="border p-2 rounded-md text-tertiary"
          >
            <option value="likes">Most Liked</option>
            <option value="newest">Newest</option>
            <option value="oldest">Oldest</option>
          </select>
        </div>
      </div>
      {!isLoading && data && data.comments && data.comments.length === 0 && (
        <p className='text-center'>Nobody is here yet. Be the first to comment!</p>
      )}

      {organizedComments.map(comment => (
        <OneComment
          key={comment.comment_id}
          comment={comment}
          liked_comments={data.likedComments}
          setParentCommentID={setParentCommentID}
          setCommentReload={setCommentReload}
          level={0} // top-level comments have level 0
        />
      ))}
    </div>

  );
};

export default CommentSection;