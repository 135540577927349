import React, { useState, useEffect } from "react";
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

import ChapterContent from "../components/ChapterContent";
import BookCards from "../components/BookCards";
import Sidebar from "../components/Sidebar";
import LatestUpdateSide from "../components/LatestUpdateSide";
import MobileToolbar from "../components/MobileToolbar";
import DesktopToolbar from "../components/DesktopToolbar";
import CommentSection from "../components/comments/CommentSection";

import { SettingsProvider } from "../components/SettingsContext";

function ChapterContentPage () {
    const [chapterName, setChapterName] = useState(null);
    const [chapterContent, setChapterContent] = useState(null);

    const { bookIDSlug, chapterID } = useParams();
    const bookID = bookIDSlug.split('-')[0];

    // Define the API fetch function using searchData
    const fetchBooks = async () => {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/${bookIDSlug}/${chapterID}`);
        if (response.data) {
            setChapterName(response.data.chapterContent.title);  // Update the chapter name
            setChapterContent(response.data.chapterContent.content);  // Update the chapter content
        }
        return response.data;
    };

    // Setup the useQuery hook
    const { data, error, isLoading, isError, refetch } = useQuery(
        [bookIDSlug, chapterID],  // Depend on searchData to refetch
        fetchBooks, 
        {
            refetchOnWindowFocus: false,
            staleTime: 300000,  // 5 minutes
            cacheTime: 1800000, // 30 minutes
        }
    );

    useEffect(() => {
        fetchBooks();  // Call the function to fetch book data
    }, [bookIDSlug, chapterID]);

    if (isLoading) return <div>Loading</div>;
    if (isError) return <div>Error</div>;

    return (
        <SettingsProvider>
            <div className={`flex-col`}>
                <MobileToolbar
                    bookIDSlug={bookIDSlug}
                    previous_chapter_id={data.chapterContent.previous_chapter_id}
                    next_chapter_id={data.chapterContent.next_chapter_id}
                    chapterList={data.chapterList}
                />
                
                <div className='flex flex-col lg:flex-row justify-center items-center lg:items-start mx-3 my-5 gap-10 max-w-full'>
                    <Helmet>
                        <meta name="description" content={chapterContent} />
                        <title>{chapterName}</title>
                    </Helmet>
                    <div className="flex flex-col items-center w-full">
                        <DesktopToolbar 
                            bookIDSlug={bookIDSlug}
                            previous_chapter_id={data.chapterContent.previous_chapter_id}
                            next_chapter_id={data.chapterContent.next_chapter_id}
                            chapterList={data.chapterList}
                        />
                        <ChapterContent bookDetails={data.bookDetails} bookIDSlug={bookIDSlug} chapterDetails={data.chapterContent} nextBook={data.nextBook} />

                        {/* <div className='flex flex-col justify-center items-center w-full'>
                            <h1 className='mb-3 text-3xl text-tertiary text-center font-bold underline'>Comments</h1>
                            <Remark42Comments chapterID={chapterID} />
                        </div> */}

                        <CommentSection book_id={bookID} chapter_id={chapterID} />

                        <div className='grid lg:gird-col-2 justify-center items-center mb-4 lg:mb-0 lg:mr-5'>
                            <h1 className='mb-3 text-3xl text-center font-bold underline'>Recommendations</h1>
                            <div className='flex'>
                                <BookCards book_list={data.randomBooks} />
                            </div>
                        </div>
                    </div>

                    <div className='justify-start items-start max-w-full lg:max-w-xs'>
                        <Sidebar />
                        <LatestUpdateSide />
                    </div>
                </div>
            </div>
        </SettingsProvider>
    );
}

export default ChapterContentPage;