import React from "react";
import { Helmet } from 'react-helmet';

import Info from "../components/Info";
import Sidebar from "../components/Sidebar";
import Hero from "../components/Hero";

function Contact () {
    const title = "Contact Us";
    const content = `
        We’d Love to Hear from You!
        <br /><br />
        Your feedback and questions are very important to us. Whether you have inquiries about our translations, suggestions for stories you’d like to see, or just want to share your thoughts, please don't hesitate to get in touch.
        <br /><br />
        <h2 class="underline">Contact Information</h2>
        <h3 class="underline">Email</h3>
        For any feedback, inquiries, or more information about our content, email us at crimsontranslations@gmail.com. We strive to respond as quickly as possible.
        <br /><br />
        <h3 class="underline">Social Media</h3>
        Feel free to message us through our social media platforms. We’re active on:
        <br />
        Facebook: Crimson Translations
        <br />
        Instagram: @CrimsonTranslations
        <br /><br />
        Thank you for your interest and support. We look forward to hearing from you!
    `;

    return (
        <div className="flex-col">
            <div className='flex flex-col lg:flex-row justify-center items-center lg:items-start m-5 gap-10 max-w-full'>
                <Helmet>
                    <meta name="description" content={content} />
                    <title>Contact Us</title>
                </Helmet>
                <Info title={title} content={content} />

                <div className='justify-start items-start lg:max-w-xs'>
                    <Sidebar />
                </div>
            </div>
        </div>
    );
}

export default Contact;