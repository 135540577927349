const commentValidation = (content, setMessage) => {
    const words = content.trim().split(/\s+/);

    if (words.length < 5) {
        setMessage('Your comment must have at least 5 words.');
        return false;
    }

    if (content.length < 20) {
        setMessage('Your comment must be at least 20 characters long.');
        return false;
    }

    // Check for words longer than 30 characters
    if (words.some(word => word.length > 50)) {
        setMessage('Words in your comment should not exceed 50 characters.');
        return false;
    }

    // if (/^\s+|\s+$|\s{2,}/.test(content)) {
    //     setMessage('Please remove excessive whitespace from your comment.');
    //     return false;
    // }

    // const prohibitedWords = ['badword1', 'badword2', 'badword3'];
    // const containsProhibitedWords = prohibitedWords.some(word => content.toLowerCase().includes(word));
    // if (containsProhibitedWords) {
    //     setMessage('Your comment contains inappropriate language.');
    //     return false;
    // }

    // Allow specific hostnames
    // const allowedHostnames = ['example.com', 'anotherdomain.com'];
    // const urlRegex = /https?:\/\/|www\./;
    // if (urlRegex.test(content)) {
    //     const urlMatches = content.match(/https?:\/\/[^\s]+|www\.[^\s]+/g);
    //     const allUrlsAllowed = urlMatches.every(url => {
    //         const hostname = new URL(url).hostname.replace('www.', '');
    //         return allowedHostnames.includes(hostname);
    //     });

    //     if (!allUrlsAllowed) {
    //         setMessage('Please do not include URLs or links in your comment.');
    //         return false;
    //     }
    // }

    if (/(.)\1{10,}/.test(content)) {
        setMessage('Please do not use excessive repeated characters.');
        return false;
    }

    // const repeatedPhrase = new RegExp(`(.+)\\1{3,}`);
    const repeatedPhrase = new RegExp(`(\\b\\w+\\b)(?:\\s+\\1\\b){3,}`);
    if (repeatedPhrase.test(content)) {
        setMessage('Your comment appears to be spammy. Please revise.');
        return false;
    }

    return true;
};

export default commentValidation;