import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import InArticle from "../components/ads/InArticle";
import BookCards from "../components/BookCards";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import axios from "axios";

function BooksByOneCriteria( { category } ) {
    const { '*' : value } = useParams();
    
    const handlePageChange = (newPageNum) => {
        setPageNum(newPageNum);
    }

    // Fetching books using React Query
    const fetchBooks = async () => {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/${category}/${value}`, {
            params: {
                pageNum: pageNum
            }
        });
        return response.data;
    };

    const { data, error, isLoading, isError, refetch } = useQuery(['books', value], fetchBooks, {
        refetchOnWindowFocus: false,
        staleTime: 300000, // 5 minutes in milliseconds
        cacheTime: 1800000, // 30 minutes in milliseconds
    });

    const [pageNum, setPageNum] = useState(1);

    useEffect(() => {
        refetch();
    }, [pageNum]);

    const capitalizeFirstLetter = (word) => {
        if (!word) return '';
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error: {error.message}</div>;
    }

    let pages = "";

    if (!isLoading && !isError) {
        pages = [...Array(data.totalPages).keys()].map(n => n + 1); // Create an array from 1 to totalPages
    }

    return (
        <div className="flex flex-col m-5">
            <Helmet>
                <meta name="description" content={`${capitalizeFirstLetter(category)}:${value}`}  />
                <title>{value} Stories</title>
            </Helmet>
            <div className='flex flex-col lg:flex-row justify-center items-center lg:items-start max-w-full'>
                <div className='flex flex-col gap-10 max-w-full'>
                    <InArticle />
                    <div className='flex flex-col justify-center items-center mb-4 lg:mb-0 lg:mr-5'>
                        <h1 className='mb-3 text-3xl text-center font-bold underline'>{capitalizeFirstLetter(category)}: {value}</h1>
                        <div className='flex'>
                            <BookCards book_list={data.books} />
                        </div>
                    </div>
                    <div className="flex justify-center items-center max-w-full gap-2">
                        {pages.map((page) => (
                            <button
                                key={page}
                                className={`px-3 py-1 ${pageNum === page ? '' : 'text-red-500 hover:underline'} font-bold`}
                                onClick={() => handlePageChange(page)}
                                disabled={pageNum === page}
                            >
                                {page}
                            </button>
                        ))}
                    </div>
                    <InArticle />
                </div>
                <div className='justify-start items-start lg:max-w-xs'>
                    <Sidebar />
                </div>
            </div>
        </div>
    );
}

export default BooksByOneCriteria;