import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const AdBlockerDetector = () => {
    const [isAdBlocked, setIsAdBlocked] = useState(false);
    const location = useLocation();

    const checkAdBlock = () => {
        if (!document.getElementById('BqgAfZPWjFYT')) {
            setIsAdBlocked(true);
            sendAdBlockEvent(); // Send event to GA
        } else {
            setIsAdBlocked(false);
        }
    };

    const sendAdBlockEvent = () => {
        if (window.gtag) {
            window.gtag('event', 'Adblock', {
                event_category: 'Ads Setting',
                event_label: 'Enabled',
                value: 1
            });
        }
    };

    useEffect(() => {
        checkAdBlock();
    }, [location]);

    useEffect(() => {
            // Initialize Google Analytics
            if (window.gtag) {
                const gaProperty = window.location.hostname === 'crimsontranslations.com' || window.location.hostname === 'www.crimsontranslations.com'
            ? 'G-14V7F8XKFR'
            : 'G-DYJE63WCMJ';

            window.gtag('config', gaProperty); // Replace with your Google Analytics tracking ID
            window.gtag('event', 'page_view', {
                page_path: window.location.pathname + window.location.search
            });
        }
    }, []);

    return (
        <div className='mx-auto px-4'>
            {isAdBlocked && (
                <div className="flex justify-center items-center bg-green-100 border border-green-400 text-green-700 rounded relative" role="alert">
                    <span className="block sm:inline px-4 py-3 text-justify text-lg">
                        Thank you for supporting us!
                        If you enjoy reading the translations on our site,
                        please consider <span className="font-bold text-red-500">disabling adblocker or whitelisting us</span> to fund the site!
                    </span>
                </div>              
            )}
        </div>
    );
};

export default AdBlockerDetector;