import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../components/users/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";

const Profile = () => {
    const { user, refreshToken, setUser } = useContext(AuthContext)
    const [ usernameMessage, setUsernameMessage ] = useState('');
    const [ username, setUsername ] = useState(user.username);
    const [ newUsername, setNewUsername ] = useState(user.username);

    const [ passwordMessage, setPasswordMessage ] = useState('');
    const [ newPassword, setNewPassword ] = useState('');
    const [ confirmedPassword, setConfirmedPassword ] = useState('');
    const navigate = useNavigate();

    const [isUsernameSubmitting, setIsUsernameSubmitting] = useState(false);
    const [isPasswordSubmitting, setIsPasswordSubmitting] = useState(false);
    const [honeypot, setHoneypot] = useState('');

    useEffect(() => {
        const checkAuth = async () => {
            if (!user) {
                navigate('/user/login'); // Redirect to login page if not setIsAuthenticated
            } else {
                try {
                    await refreshToken(); // Attempt to refresh token if authenticated
                } catch (error) {
                    navigate('/user/login'); // Redirect to login if refresh token fails
                }
            }
        };
        
        checkAuth();
    }, [user, navigate, refreshToken]);

    const handleUsernameSubmit = async (e) => {
        e.preventDefault();
        setIsUsernameSubmitting(true);

        if (username == newUsername) {
            setUsernameMessage('The new username cannot be the same as the old username. Please choose a different username.');
            setTimeout(() => setIsUsernameSubmitting(false), 1000); // Re-enable the button after 1 second
            return;
        }

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                setUsernameMessage('No token found. Please log in again.');
                setTimeout(() => setIsUsernameSubmitting(false), 1000); // Re-enable the button after 1 second
                return;
            }
        
            // Set the Authorization header
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
        
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/user/profile/change-username`, { newUsername, honeypot }, config);
            setUsernameMessage(response.data.message);
        
            const storedUser = JSON.parse(localStorage.getItem('user'));
            
            if (storedUser) {
                localStorage.setItem('user', JSON.stringify({ ...storedUser, username: newUsername }));
                const newStoredUser = JSON.parse(localStorage.getItem('user'));
                setUser(newStoredUser);
            }
            setUsername(newUsername);
        } catch (error) {
            setUsernameMessage('An error occurred. Please try again.');
            console.error('Error:', error.response ? error.response.data : error.message);
        } finally {
            setTimeout(() => setIsUsernameSubmitting(false), 1000); // Re-enable the button after 1 second
        }
    };
      

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        setIsPasswordSubmitting(true);

        if (newPassword !== confirmedPassword) {
            setPasswordMessage('New password and confirmed password are not the same, please check again.');
            setTimeout(() => setIsPasswordSubmitting(false), 1000); // Re-enable the button after 1 second
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/user/profile/change-password`, { newPassword, honeypot });
            setPasswordMessage(response.data.message);
            // if (response.data.success = true) {
            //     setPasswordMessage('Password changed successfully');
            // }
        } catch (error) {
            setPasswordMessage('An error occurred. Please try again.');
        } finally {
            setNewPassword('');
            setConfirmedPassword('');
            setTimeout(() => setIsPasswordSubmitting(false), 1000); // Re-enable the button after 1 second
        }
    };

    return (
        <div className="flex flex-col items-center p-4">
            <Helmet>
                <meta name="profile" content="Changing user data" />
                <title>Profile</title>
            </Helmet>
            <form onSubmit={handleUsernameSubmit} className="flex flex-col justify-center items-center w-full lg:w-1/2 p-4 bg-white text-tertiary rounded-md shadow-md mb-6">
                <h2 className="text-2xl mb-4">Change Username</h2>
                {usernameMessage && <p className="text-red-500 mb-4">{usernameMessage}</p>}
                <div className="w-full mb-4">
                <div className="grid grid-cols-4 gap-2 items-center">
                    <label className='col-span-1 text-center'>Username:</label>
                    <input
                    className='col-span-3 p-2 border border-tertiary rounded-md'
                    type="text"
                    value={newUsername}
                    onChange={(e) => setNewUsername(e.target.value)}
                    required
                    />
                </div>
                </div>
                <button type="submit" disabled={isUsernameSubmitting} className="bg-red-400 text-white py-2 px-4 rounded-md hover:bg-tertiary transition duration-300">Change Username</button>
            </form>
            <form onSubmit={handlePasswordSubmit} className="flex flex-col justify-center items-center w-full lg:w-1/2 p-4 bg-white text-tertiary rounded-md shadow-md">
                <h2 className="text-2xl mb-4">Reset Password</h2>
                {passwordMessage && <p className="text-red-500 mb-4">{passwordMessage}</p>}
                <div className="w-full mb-4">
                    <div className="grid grid-cols-4 gap-2 items-center mb-4">
                        <label className='col-span-1 text-center'>New Password:</label>
                        <input
                            className='col-span-3 p-2 border border-tertiary rounded-md'
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className="grid grid-cols-4 gap-2 items-center">
                        <label className='col-span-1 text-center'>Confirm New Password:</label>
                        <input
                            className='col-span-3 p-2 border border-tertiary rounded-md'
                            type="password"
                            value={confirmedPassword}
                            onChange={(e) => setConfirmedPassword(e.target.value)}
                            required
                        />
                    </div>
                    {/*honeypot*/}
                    <input
                        className="hidden text-tertiary"
                        type="text"
                        name="phone_number"
                        value={honeypot}
                        onChange={(e) => setHoneypot(e.target.value)}
                        tabIndex="-1"
                        autoComplete="off"
                    />
                </div>
                <button type="submit" disabled={isPasswordSubmitting} className="bg-red-400 text-white py-2 px-4 rounded-md hover:bg-tertiary transition duration-300">Reset Password</button>
            </form>
        </div>
    )

}

export default Profile;