import React from "react";
import { Helmet } from 'react-helmet';

import Info from "../components/Info";
import Sidebar from "../components/Sidebar";
import Hero from "../components/Hero";

function Disclaimer () {
    const title = "Disclaimer";
    const content = `
        The translations provided on Crimson Translations are done to the best of our ability and for general information purposes only. We strive to provide accurate and up-to-date content, however, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the translations or the information contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.
    `;

    return (
        <div className="flex-col">
            <div className='flex flex-col lg:flex-row justify-center items-center lg:items-start m-5 gap-10 max-w-full'>
                <Helmet>
                    <meta name="description" content={content} />
                    <title>Disclaimer</title>
                </Helmet>
                <Info title={title} content={content} />

                <div className='justify-start items-start lg:max-w-xs'>
                    <Sidebar />
                </div>
            </div>
        </div>
    );
}

export default Disclaimer;