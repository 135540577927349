import React from "react";
import { Helmet } from 'react-helmet';
import LoginForm from "../../components/users/LoginForm";

function LoginPage () {
    return (
        <div className="flex justify-center items-center">
            <Helmet>
                <meta name="login" content="User login" />
                <title>Login</title>
            </Helmet>
            <LoginForm />
        </div>
    );
}

export default LoginPage;