import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { AuthContext } from '../../components/users/AuthContext';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../../components/ThemeContext';

const createSlug = (book_id, english_book_name) => {
    let slug = `${book_id} - ${english_book_name}`;
    slug = slug.replace(/[^a-zA-Z0-9 -]/g, "").replace(/[\s-]+/g, '-').toLowerCase();
    return slug;
};

const ReplyNotification = () => {
    const { user, refreshToken } = useContext(AuthContext);
    const [pageNum, setPageNum] = useState(1);
    const { themeName } = useContext(ThemeContext);

    const navigate = useNavigate();

    useEffect(() => {
        const checkAuth = async () => {
            if (!user) {
                navigate('/user/login'); // Redirect to login page if not setIsAuthenticated
            } else {
                try {
                    await refreshToken(); // Attempt to refresh token if authenticated
                } catch (error) {
                    navigate('/user/login'); // Redirect to login if refresh token fails
                }
            }
        };
        
        checkAuth();
    }, [user, navigate, refreshToken]);

    const handlePageChange = (newPageNum) => {
        setPageNum(newPageNum);
    };

    const fetchReplies = async () => {
        if (user) {
            await refreshToken();
        }
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/replies`, {
            params: {
                pageNum: pageNum
            }
        });
        
        return response.data;
    };

    const fetchUnreadCount = async () => {
        if (user) {
            await refreshToken();
        }
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/replies/unread-count`);
        return response.data.unreadCount;
    };

    const markRepliesAsRead = async () => {
        if (user) {
            await refreshToken();
        }
        await axios.put(`${process.env.REACT_APP_BASE_URL}/api/user/replies/mark-read`);
    };

    const { data, error, isLoading, isError, refetch } = useQuery(['replies', user.user_id, pageNum], fetchReplies, {
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        refetch();
    }, [pageNum]);

    const { data: unreadCount } = useQuery(['unreadCount', user.user_id], fetchUnreadCount, {
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (data) {
            markRepliesAsRead();
        }
    }, [data]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error: {error.message}</div>;
    }

    let pages = "";
    if (!isLoading && !isError) {
        pages = [...Array(data.totalPages).keys()].map(n => n + 1);
    }

    return (
        <div className="flex flex-col items-center p-5">
            <Helmet>
                <meta name="reply-notification" content="Reply notification" />
                <title>Reply Notification</title>
            </Helmet>
            <h1 className='mb-5 text-3xl text-center font-bold underline'>Reply Notifications</h1>
            <div className='w-full max-w-3xl'>
                {unreadCount > 0 && <div className="mb-4 text-lg text-red-500 text-center">You have {unreadCount} unread replies.</div>}
                {!isLoading && data.replies.length === 0 && <p className="text-center">No one has replied to you yet! Come back later!</p>}
                <div className='flex flex-col gap-4'>
                    {data.replies && data.replies.map(reply => {
                        const slug = createSlug(reply.book_id, reply.english_book_name);
                        return (
                        <div key={reply.comment_id} className={`p-4 border rounded-lg text-tertiary ${reply.notification_is_read ? 'bg-white' : 'bg-red-200'}`}>
                            <div className="flex flex-row gap-1 mb-2">
                                <strong className="block">Story:</strong>
                                <a href={`/${slug}`} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">{reply.english_book_name}</a>
                            </div>
                            {reply.chapter_title && (
                            <div className="flex flex-row gap-1 mb-2">
                                <strong className="block">Chapter:</strong>
                                <a href={`/${slug}/${reply.chapter_id}`} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">{reply.chapter_title}</a>
                            </div>
                            )}
                            <div className="flex flex-col mb-2">
                                <strong className="block">Comment:</strong>
                                <a 
                                    href={`/${slug}${reply.chapter_id ? `/${reply.chapter_id}` : ''}#${reply.parent_comment_id}`} 
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                    className="text-blue-500 hover:underline break-words overflow-hidden"
                                    style={{
                                        display: '-webkit-box',
                                        WebkitLineClamp: 5,
                                        WebkitBoxOrient: 'vertical'
                                    }}
                                >
                                    {reply.parent_comment_content}
                                </a>
                            </div>
                            <div className="flex flex-col mb-2">
                                <strong className="block">Reply:</strong>
                                <a href={`/${slug}${reply.chapter_id ? `/${reply.chapter_id}` : ''}#${reply.comment_id}`} 
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                    className="text-blue-500 hover:underline break-words overflow-hidden"
                                    style={{
                                        display: '-webkit-box',
                                        WebkitLineClamp: 6,
                                        WebkitBoxOrient: 'vertical'
                                    }}
                                >
                                    {reply.username}:
                                    <br />
                                    {reply.comment_content}
                                </a>
                            </div>
                        </div>
                        );
                    })}
                </div>
                <div className="flex justify-center items-center max-w-full gap-2 mt-4">
                    {pages.map((page) => (
                        <button
                            key={page}
                            className={`px-3 py-1 ${pageNum === page ? '' : 'text-red-500 hover:underline'} font-bold`}
                            onClick={() => handlePageChange(page)}
                            disabled={pageNum === page}
                        >
                            {page}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ReplyNotification;