import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import BookChaptersList from './BookChaptersList';
import { SettingsContext } from './SettingsContext';
import { ThemeContext } from './ThemeContext';

const { DecreaseFontSizeIcon, IncreaseFontSizeIcon, DecreasePageSpaceIcon, IncreasePageSpaceIcon, 
  LeftArrowIcon, RightArrowIcon, SettingIcon, ListIcon, BookIcon, CommentIcon } = require('./icons/Icons');

const MobileToolbar = ({ bookIDSlug, previous_chapter_id, next_chapter_id, chapterList }) => {
  const [isToolbarVisible, setIsToolbarVisible] = useState(true);
  const navigate = useNavigate();
  let touchStartX = useRef(0);
  let touchStartY = useRef(0);
  const touchTimer = useRef(null);

  const [isSettingsVisible, setIsSettingsVisible] = useState(false);

  const [isChapterListVisible, setIsChapterListVisible] = useState(false);

  const { increaseFontSize, decreaseFontSize, isMaxFontSize, isMinFontSize,
    increasePageSpace, decreasePageSpace, isMaxPageSpace, isMinPageSpace } = useContext(SettingsContext);

  const { setTheme } = useContext(ThemeContext);

  // const handleScroll = () => {
  //   setIsToolbarVisible(false);
  //   setIsChapterListVisible(false);
  // };

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
    touchStartY.current = e.touches[0].clientY;
    touchTimer.current = setTimeout(() => {
      // If the touch lasts longer than 200ms, it's not considered a quick touch
      touchTimer.current = null;
    }, 200); // Adjust this time to set the maximum duration for a "quick touch"
  };

  const handleTouchEnd = (e) => {
    if (touchTimer.current) {
      clearTimeout(touchTimer.current);
      touchTimer.current = null;
      const touchEndX = e.changedTouches[0].clientX;
      const touchEndY = e.changedTouches[0].clientY;

      if (
        Math.abs(touchEndX - touchStartX.current) < 10 &&
        Math.abs(touchEndY - touchStartY.current) < 10
      ) {
        // If the touch didn't move significantly, toggle visibility
        setIsToolbarVisible((prev) => !prev);
        setIsChapterListVisible(false);
      }
    }
  };

  const handleTouchMove = () => {
    if (touchTimer.current) {
      clearTimeout(touchTimer.current);
      touchTimer.current = null;
    }
    setIsToolbarVisible(false);
  };
  
  useEffect(() => {
    // window.addEventListener('scroll', handleScroll);
    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchend', handleTouchEnd);
    window.addEventListener('touchmove', handleTouchMove);

    return () => {
      // window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchend', handleTouchEnd);
      window.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  const handleShowChapters = () => {
    setIsChapterListVisible(true);
  };

  const handleCloseChapters = () => {
    setIsChapterListVisible(false);
  };

  const handleOverlayClick = (e) => {
    e.stopPropagation();
    handleCloseChapters();
  };

  const handleBoxClick = (e) => {
    // Prevent the overlay's onClick from being triggered
    e.stopPropagation();
  };

  const handleScrollToComment = (e) => {
    e.stopPropagation();
    const element = document.getElementById(`comment-section`);
    if (element) {
        element.scrollIntoView({ behavior: 'auto', block: 'start' });
    }
  }

  return (
    <div
      onTouchStart={ (e) => {e.stopPropagation();} }
      onTouchEnd={ (e) => {e.stopPropagation();} }
      onTouchMove={ (e) => {e.stopPropagation();} }
      className="block lg:hidden absolute z-50"
    >
        {isChapterListVisible && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-5" onTouchStart={handleOverlayClick}>
                <div className="bg-white px-4 pb-4 rounded-lg shadow-lg w-11/12 max-h-full overflow-auto" onTouchStart={handleBoxClick}>
                  <div className='sticky top-0 flex flex-row justify-between items-center py-3 bg-white'>
                    <h2 className="text-tertiary text-3xl font-bold">Chapter List</h2>
                    <button className="top-2 right-2 text-tertiary text-3xl" onClick={handleCloseChapters}>
                        X
                    </button>
                  </div>
                  <BookChaptersList bookIDSlug={bookIDSlug} bookChapters={chapterList} fontColor='text-tertiary' />
                </div>
            </div>
        )}
        
        <div className={`fixed bottom-0 w-full bg-secondary text-white transition-transform duration-300 ${isToolbarVisible ? 'transform translate-y-0' : 'transform translate-y-full'}`}>
          {isSettingsVisible && (
              <div className="flex flex-col justify-around pb-2 border border-black bg-gray-100">
                  <div className='grid grid-cols-7 border-b border-black p-1'>
                    <label className='flex justify-end items-center col-span-2 text-tertiary text-right'>Font Size:</label>
                    <div className='grid grid-cols-4 col-span-5'>
                        <button onClick={decreaseFontSize} className={`flex justify-center items-center w-full h-full ${isMinFontSize && 'opacity-30'}`}>
                            <DecreaseFontSizeIcon size={16} color={'text-black'} />
                            {/* <img src="/assets/images/common/vec_decrease_font_size.svg" className={`w-10 h-10 ${isMinFontSize && 'opacity-20'}`} alt="Decrease Font Size" /> */}
                        </button>
                        <button onClick={increaseFontSize} className={`flex justify-center items-center w-full h-full ${isMaxFontSize && 'opacity-30'}`}>
                            <IncreaseFontSizeIcon size={16} color={'text-black'} />
                            {/* <img src="/assets/images/common/vec_increase_font_size.svg" className={`w-10 h-10 ${isMaxFontSize && 'opacity-20'}`} alt="Increase Font Size" /> */}
                        </button>
                    </div>
                  </div>

                  {/* <div className='grid grid-cols-7 border-b border-black p-1'>
                    <label className='flex justify-end items-center col-span-2 text-tertiary text-right'>Page Space:</label>
                    <div className='grid grid-cols-4 col-span-5'>
                        <button onClick={decreasePageSpace} className={`flex justify-center items-center w-full h-full ${isMinPageSpace && 'opacity-30'}`}>
                            <DecreasePageSpaceIcon size={16} color={'text-black'} />
                        </button>
                        <button onClick={increasePageSpace} className={`flex justify-center items-center w-full h-full ${isMaxPageSpace && 'opacity-30'}`}>
                            <IncreasePageSpaceIcon size={16} color={'text-black'} />
                        </button>
                    </div>
                  </div> */}
                  
                  <div className='grid grid-cols-7 p-1'>
                    <label className='flex justify-end items-center col-span-2 text-tertiary text-right'>Theme:</label>
                    <div className='grid grid-cols-4 col-span-5 gap-y-2 place-items-center'>
                        <button onClick={() => setTheme('crimson')} className='w-fit'>
                            <span className="block w-10 h-10 bg-primary border-2 border-black">
                              <label className='flex justify-center items-center text-center text-tertiary text-3xl'>T</label>
                            </span>
                        </button>
                        <button onClick={() => setTheme('jade')} className='w-fit'>
                            <span className="block w-10 h-10 bg-green-100 border-2 border-black">
                              <label className='flex justify-center items-center text-center text-tertiary text-3xl'>T</label>
                            </span>
                        </button>
                        <button onClick={() => setTheme('azure')} className='w-fit'>
                            <span className="block w-10 h-10 bg-blue-100 border-2 border-black">
                              <label className='flex justify-center items-center text-center text-tertiary text-3xl'>T</label>
                            </span>
                        </button>
                        <button onClick={() => setTheme('light')} className='w-fit'>
                            <span className="block w-10 h-10 bg-white border-2 border-black">
                              <label className='flex justify-center items-center text-center text-black text-3xl'>T</label>
                            </span>
                        </button>
                        <button onClick={() => setTheme('dark')} className='w-fit'>
                            <span className="block w-10 h-10 bg-black border-2 border-black">
                              <label className='flex justify-center items-center text-center text-white text-3xl'>T</label>
                            </span>
                        </button>
                        <button onClick={() => setTheme('gray')} className='w-fit'>
                            <span className="block w-10 h-10 bg-gray-700 border-2 border-black">
                              <label className='flex justify-center items-center text-center text-white text-3xl'>T</label>
                            </span>
                        </button>
                    </div>
                  </div>
              </div>
          )}

          <div className="flex justify-around py-2">
              {
                  previous_chapter_id ? (
                    <a href={`/${bookIDSlug}/${previous_chapter_id}`}>
                      <LeftArrowIcon size={20} color={'text-black'} />
                      {/* <img src="/assets/images/common/vec_left.svg" className="w-10 h-10" alt="Previous Chapter" /> */}
                    </a>
                  ) : (
                    <span className="w-10 h-10 opacity-20">
                      <LeftArrowIcon size={20} color={'text-black'} />
                      {/* <img src="/assets/images/common/vec_left.svg" className="w-10 h-10" alt="Previous Chapter" /> */}
                    </span>
                  )
              }
              {
                  next_chapter_id ? (
                    <a href={`/${bookIDSlug}/${next_chapter_id}`}>
                      <RightArrowIcon size={20} color={'text-black'} />
                      {/* <img src="/assets/images/common/vec_right.svg" className="w-10 h-10" alt="Next Chapter" /> */}
                    </a>
                  ) : (
                    <span className="w-10 h-10 opacity-20">
                      <RightArrowIcon size={20} color={'text-black'} />
                      {/* <img src="/assets/images/common/vec_right.svg" className="w-10 h-10" alt="Next Chapter" /> */}
                    </span>
                  )
              }
              <a href={`/${bookIDSlug}`}>
                  <span className="w-10 h-10">
                    <BookIcon size={22} color={'text-black'} />
                  </span>
                  {/* <img src="/assets/images/common/vec_book.svg" className="w-10 h-10" alt="Index" /> */}
              </a>
              <button onClick={() => handleShowChapters()}>
                  <span className="w-10 h-10">
                    <ListIcon size={22} color={isChapterListVisible ? 'text-blue-500' : 'text-black'} />
                  </span>
                  {/* <img src="/assets/images/common/vec_list.svg" className="w-10 h-10" alt="Chapter List" /> */}
              </button>
              <button onClick={handleScrollToComment}>
                  <span className="w-10 h-10">
                    <CommentIcon size={22} color={'text-black'} />
                  </span>
              </button>
              <button onClick={() => setIsSettingsVisible(!isSettingsVisible)}>
                  <span className="w-10 h-10">
                    <SettingIcon size={20} color={isSettingsVisible ? 'text-blue-500' : 'text-black'} />
                  </span>
                  {/* <img src="/assets/images/common/vec_setting.svg" className="w-10 h-10" alt="Settings" /> */}
              </button>
          </div>
        </div>
    </div>
  );
};

export default MobileToolbar;