import React from "react";

function Footer () {
    return (
        <footer className="bg-secondary">
            <div className="container mx-auto flex flex-col items-center text-base">
                <div className="flex flex-wrap justify-center items-center gap-x-5 lg:gap-x-20 text-tertiary font-bold text-sm lg:text-md text-center">
                    <a href="/about-us" className="hover:text-primary">About Us</a>
                    <a href="/contact-us" className="hover:text-primary">Contact Us</a>
                    <a href="/dmca" className="hover:text-primary">DMCA</a>
                    <a href="/terms-of-service" className="hover:text-primary">Terms of Service</a>
                    <a href="/privacy-policy" className="hover:text-primary">Privacy Policy</a>
                    <a href="/disclaimer" className="hover:text-primary">Disclaimer</a>
                </div>
                <div>
                    <p className="text-tertiary text-xs lg:text-md">© {new Date().getFullYear()} Crimson Translations. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;