/*
OneComment.js

Take comment, liked_comments (optional), setParentCommentID, and setCommentReload from parent component

UseState: parentCommentID, default null
UseState: replyCommentBoxVisibility, default false
UseState: replyCommentContent, default ''

Check authenticated checking the existance of "user" in AuthContext

The div will have the id of "comment_id" in comment

A profile picture that use Gravatar by using "email" in comment, when the "email" is null the profile picture will not be shown
A username by using "username" in comment, when the "username" is null the username will be "[deleted]"
A date by using "comment_time" in comment
If the "parent_comment_id" from comment is not null, a "reply to: " will be shown and follow by the "username" from the comment that have "parent_comment_id", when the "username" is null the username will be "[deleted]"
Comment content by using "comment_content" in comment, displayed using ReactMarkdown, when the "comment_content" is null the content will be "[deleted]"

A like button:
Show no matter the user is authenticated or not. Besides it is a number from "like_count" from "comment".
When user click, if user is not authenticated, a popup of "You must login to like a comment."
If user is authenticated,
Check whether the "comment_id" in comment is included in the "comment_id" in liked_comments.
Will show a different style when the "comment_id" is included in "comment_id" in liked_comments (user already like it).
Clicking the like button when the user havent like it will call /${process.env.REACT_APP_BASE_URL}/comment/like-comments, and pass comment_id. The style will change immediately.
Clicking the like button when the user liked it will call /${process.env.REACT_APP_BASE_URL}/comment/delete-like-comments, and pass comment_id. The style will change immediately.
If the response status is 403, it will have a popup of "message" from response

A delete button:
Only show when "user_id" from comment is the same as the "user" from AuthContext.
Clicking the delete button will have a popup of asking user "Are you sure to delete this comment?" and choice "Yes" and "No".
If user choose no, nothing will happen.
If user choose yes, call /${process.env.REACT_APP_BASE_URL}/comment/delete-comments, and pass comment_id.

A reply button:
Only show when is authenticated.
Clicking the reply button will show a hidden comment box and a hidden "Reply" button under the comment content
The comment content will be set everytime the content in the input changed.
Clicking the button will call /${process.env.REACT_APP_BASE_URL}/comment/post-comments, and pass book_id from comment, chapter_id from comment, parent_comment_id from "comment_id" of comment, comment_content.
Set commentReload to 'true'.
*/

import React, { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../users/AuthContext';
import { ThemeContext } from '../ThemeContext';
import ReactMarkdown from 'react-markdown';
import axios from 'axios';
import md5 from 'md5';
import CustomMarkdown from '../markdown/CustomMarkdown';
import LikeButton from '../buttons/LikeButton';
import commentValidation from './commentValidation';
import '../styles/markdown.css';

const { DeleteIcon, EditIcon, ExpandIcon, ReplyIcon, FlagIcon } = require('../icons/Icons');

const OneComment = ({ comment, liked_comments = [], setParentCommentID, setCommentReload, level }) => {
  const { user, refreshToken } = useContext(AuthContext);
  const [replyCommentBoxVisibility, setReplyCommentBoxVisibility] = useState(false);
  const [replyCommentContent, setReplyCommentContent] = useState('');
  const [replyCommentMessage, setReplyCommentMessage] = useState('');

  const [editCommentBoxVisibility, setEditCommentBoxVisibility] = useState(false);
  const [editCommentContent, setEditCommentContent] = useState(comment.comment_content);
  const [editCommentMessage, setEditCommentMessage] = useState('');

  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [popupCallback, setPopupCallback] = useState(null);
  const [showLogin, setShowLogin] = useState(false);

  const [liked, setLiked] = useState(liked_comments.some(liked => liked.comment_id === comment.comment_id));
  const [likeCount, setLikeCount] = useState(comment.like_count);

  const [isParentReported, setIsParentReported] = useState(comment.parent_report_count >= 10);
  const [isReported, setIsReported] = useState(comment.report_count >= 10);

  const [replyVisibility, setReplyVisibility] = useState(true);

  const [isLikeSubmitting, setIsLikeSubmitting] = useState(false);
  const [isEditSubmitting, setIsEditSubmitting] = useState(false);
  const [isReplySubmitting, setIsReplySubmitting] = useState(false);
  const [honeypot, setHoneypot] = useState('');

  const { themeName } = useContext(ThemeContext);

  const handleLike = async () => {
    setIsLikeSubmitting(true);
    if (!user) {
      setPopupMessage('You must log in to like a comment.');
      setPopupCallback(null);
      setShowLogin(true);
      setShowPopup(true);
      setTimeout(() => setIsLikeSubmitting(false), 500); // Re-enable the button after 0.5 second
      return;
    } else {
      await refreshToken();
    }

    try {
      setLiked(!liked);
      setLikeCount(liked ? likeCount - 1 : likeCount + 1);
      
      const endpoint = liked
        ? `${process.env.REACT_APP_BASE_URL}/api/comment/delete-like-comments`
        : `${process.env.REACT_APP_BASE_URL}/api/comment/like-comments`;
      const res = await axios.post(endpoint, { comment_id: comment.comment_id });
    } catch (error) {
      console.error('Error liking/unliking comment:', error);
      if (error.response && error.response.status === 403 || error.response.status === 429) {
        setPopupMessage(error.response.data.message);
        setPopupCallback(null);
        setShowPopup(true);
      }
    } finally {
      setTimeout(() => setIsLikeSubmitting(false), 500); // Re-enable the button after 0.5 second
    }
  };

  const handleEdit = async () => {
    setIsEditSubmitting(true);
    if (user) {
      await refreshToken();
    }

    const passValidation = commentValidation(editCommentContent, setEditCommentMessage);
    if (!passValidation) {
      setTimeout(() => setIsEditSubmitting(false), 1000); // Re-enable the button after 1 second
      return;
    }

    try {
      const currentTime = new Date().getTime();
      const lastCommentTime = localStorage.getItem('lastCommentTime');

      if (lastCommentTime && (currentTime - lastCommentTime) < 30000) {
        const remainingTime = Math.ceil((30000 - (currentTime - lastCommentTime)) / 1000);
        setEditCommentMessage(`Please wait for ${remainingTime} seconds before commenting again.`);
        setTimeout(() => setIsEditSubmitting(false), 1000); // Re-enable the button after 1 second
        return;
      }

      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/comment/edit-comments`, {
                new_comment_content: editCommentContent,
                comment_id: comment.comment_id,
                honeypot: honeypot
              });

      if (res.data.success == false) {
        setEditCommentMessage(res.data.message);
      } else {
        setEditCommentBoxVisibility(false);
        setCommentReload(true);
        setEditCommentMessage('');
        localStorage.setItem('lastCommentTime', currentTime); // Save the last comment time in localStorage
      }
    } catch (error) {
      console.error('Error posting reply:', error);
    } finally {
      setTimeout(() => setIsEditSubmitting(false), 1000); // Re-enable the button after 1 second
    }
  };

  const handleDelete = async () => {
    if (user) {
      await refreshToken();
    }
    setPopupMessage('Are you sure you want to delete this comment?');
    setPopupCallback(() => async () => {
      try {
        await axios.post(`${process.env.REACT_APP_BASE_URL}/api/comment/delete-comments`, { comment_id: comment.comment_id });
        setCommentReload(true);
        setShowPopup(false);
        setReplyCommentBoxVisibility(false);
        setEditCommentBoxVisibility(false);
      } catch (error) {
        console.error('Error deleting comment:', error);
        setShowPopup(false);
      }
    });
    setShowPopup(true);
  };

  const handleReport = async () => {
    if (user) {
      await refreshToken();
    }

    setPopupMessage('Are you sure you want to report this comment?');
    setPopupCallback(() => async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/comment/report-comments`, { comment_id: comment.comment_id });
        setPopupMessage(res.data.message);
        setPopupCallback(null);
      } catch (error) {
        console.error('Error deleting comment:', error);
        setShowPopup(false);
      }
    });
    setShowPopup(true);


  };

  const handleReply = async () => {
    setIsReplySubmitting(true);
    if (user) {
      await refreshToken();
    }

    const passValidation = commentValidation(replyCommentContent, setReplyCommentMessage);
    if (!passValidation) {
      setTimeout(() => setIsReplySubmitting(false), 1000); // Re-enable the button after 1 second
      return;
    }

    try {
      const currentTime = new Date().getTime();
      const lastCommentTime = localStorage.getItem('lastCommentTime');
  
      if (lastCommentTime && (currentTime - lastCommentTime) < 30000) {
        const remainingTime = Math.ceil((30000 - (currentTime - lastCommentTime)) / 1000);
        setReplyCommentMessage(`Please wait for ${remainingTime} seconds before commenting again.`);
        setTimeout(() => setIsReplySubmitting(false), 1000); // Re-enable the button after 1 second
        return;
      }

      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/comment/post-comments`, {
                book_id: comment.book_id,
                chapter_id: comment.chapter_id,
                parent_comment_id: comment.comment_id,
                comment_content: replyCommentContent,
                honeypot: honeypot,
              });

      if (res.data.success == false) {
        setReplyCommentMessage(res.data.message);
      } else {
        setReplyCommentContent('');
        setReplyCommentBoxVisibility(false);
        setCommentReload(true);
        setReplyCommentMessage('');
        localStorage.setItem('lastCommentTime', currentTime); // Save the last comment time in localStorage

        // Get the comment_id from the response and redirect to the specific comment
        const commentId = res.data.comment_id;

        // Wait for the comments to reload before scrolling
        setTimeout(() => {
          // Apply scroll and highlight logic
          const element = document.getElementById(`${commentId}`);
          if (element) {
              element.scrollIntoView({ behavior: 'auto', block: 'start' });
              const elementColor = ((themeName == 'dark' || themeName == 'gray') ? 'bg-red-500' : 'bg-red-200')
              element.classList.add(elementColor, 'transition', 'duration-1000');
              // Optionally remove the highlight after a delay
              setTimeout(() => {
                  element.classList.remove(elementColor);
              }, 2000); // Adjust the duration as needed
          }
      }, 500); // Adjust the delay as needed to ensure comments are loaded
      }
    } catch (error) {
      console.error('Error posting reply:', error);
    } finally {
      setTimeout(() => setIsReplySubmitting(false), 1000); // Re-enable the button after 1 second
    }
  };

  const handlePopupClose = () => {
    if(showLogin == true) {
      setShowPopup(false);
      setShowLogin(false);
    } else {
      setShowPopup(false);
    }
  }

  const handlePopupLogin = () => {
    if(showLogin == true) {
      setShowPopup(false);
      setShowLogin(false);
      window.open('/user/login', '_blank');
    } else {
      setShowPopup(false);
    }
  }

  return (
    <div id={comment.comment_id} className={`relative pt-1 mt-1 ${level >= 5 ? '' : 'border-l-2 border-tertiary pl-4'}`}>
      <div className="flex items-start mb-2">
        {comment.email && (
          <img
            src={`https://www.gravatar.com/avatar/${(comment.deleted_comment || isReported) ? md5('crimsontranslations@gmail.com') : md5(comment.email)}?d=identicon`}
            alt="profile"
            className="w-10 h-10 rounded-full mr-3"
          />
        )}
        <div className="flex-1">
          <div className="flex justify-between items-center mb-1">
            <div>
              <p className="font-semibold break-words">{comment.deleted_comment ? '[deleted]' : isReported ? '[removed]' : comment.username}</p>
              <p className="text-sm">{new Date(comment.comment_time).toLocaleString()}</p>
            </div>
          </div>
        </div>
      </div>

      {comment.parent_comment_id && (
        <span>
          <p className="text-sm break-words italic">
            Reply to: {comment.parent_deleted ? '[deleted]' : isParentReported ? '[removed]' : comment.parent_username}
          </p>
        </span>
      )}
      <CustomMarkdown markdown={comment.deleted_comment ? '[deleted]' : isReported ? '[This comment has been removed due to numerous reports.]' : comment.comment_content} />

      {/*honeypot*/}
      <input
          className="hidden text-tertiary"
          type="text"
          name="phone_number"
          value={honeypot}
          onChange={(e) => setHoneypot(e.target.value)}
          tabIndex="-1"
          autoComplete="off"
      />

      <div className="flex items-center space-x-1.5 mt-2">
          <div className='inline-flex justify-center items-center gap-1'>
            <LikeButton liked={liked} onClick={handleLike} size={13} isSubmitting={isLikeSubmitting} />
            <span className="text-md">{likeCount}</span>
          </div>
          {user && comment.user_id === user.user_id && !comment.deleted_comment && !isReported && (
            <button onClick={() => { setEditCommentBoxVisibility(!editCommentBoxVisibility); setReplyCommentBoxVisibility(false); }} className="ml-2">
              <EditIcon size={13} color={editCommentBoxVisibility && 'text-purple-500'} />
            </button>
          )}
          {!comment.deleted_comment && !isReported && (
            <button onClick={() => { setReplyCommentBoxVisibility(!replyCommentBoxVisibility); setEditCommentBoxVisibility(false); }} className="ml-2">
              <ReplyIcon size={13} color={replyCommentBoxVisibility && 'text-blue-500'} />
            </button>
          )}
          {user && comment.user_id === user.user_id && !comment.deleted_comment && !isReported && (
            <button onClick={handleDelete} className="ml-2">
              <DeleteIcon size={13} color={'text-red-700'} />
            </button>
          )}
          {user && comment.user_id !== user.user_id && user.email_verified == true && !comment.deleted_comment && !isReported && (
            <button onClick={handleReport} className="ml-2">
              <FlagIcon size={13} color={'text-red-700'} />
            </button>
          )}
          <div className='flex justify-start items-center gap-1'>
            <button onClick={() => setReplyVisibility(!replyVisibility)} className="flex items-center">
              <ExpandIcon size={13} color={replyVisibility && 'text-green-500'} />
            </button>
            <span className="text-md">{comment.children ? comment.children.length : 0}</span>
          </div>
        </div>

      {editCommentBoxVisibility && (
        <div className="flex flex-col justify-center items-center gap-2 mt-2">
          <textarea
            value={editCommentContent}
            onChange={(e) => setEditCommentContent(e.target.value)}
            disabled={!user || !user.email_verified}
            className={`border p-2 w-full h-24 rounded-md text-tertiary ${editCommentMessage ? 'border-red-500' : 'border-gray-300'}`}
            style={{ opacity: user ? 1 : 0.5 }}
            placeholder={(!user || !user.email_verified) ? 'Please log in and verify your email to reply.' : 'Edit your comment...'}
          />
          {editCommentMessage && <p className='text-red-500'>{editCommentMessage}</p>}
          <button disabled={!user || !user.email_verified || isEditSubmitting} onClick={handleEdit} className="bg-red-400 text-white py-2 px-4 rounded-md mb-2 hover:bg-tertiary transition duration-300">
            Edit
          </button>
        </div>
      )}

      {replyCommentBoxVisibility && (
        <div className="flex flex-col justify-center items-center gap-2 mt-2">
          <textarea
            value={replyCommentContent}
            onChange={(e) => setReplyCommentContent(e.target.value)}
            disabled={!user || !user.email_verified}
            className={`border p-2 w-full h-24 rounded-md text-tertiary ${replyCommentMessage ? 'border-red-500' : 'border-gray-300'}`}
            style={{ opacity: user ? 1 : 0.5 }}
            placeholder={(!user || !user.email_verified) ? 'Please log in and verify your email to reply.' : 'Reply to a comment...'}
          />
          {replyCommentMessage && <p className='text-red-500'>{replyCommentMessage}</p>}
          {!user && <p className='text-red-500'>You must <a href="/user/login" target="_blank" rel="noopener noreferrer" className='text-blue-400 underline hover:no-underline'>log in</a> to reply.</p>}
          <button disabled={!user || !user.email_verified || isReplySubmitting} onClick={handleReply} className="bg-red-400 text-white py-2 px-4 rounded-md mb-2 hover:bg-tertiary transition duration-300">
            Reply
          </button>
        </div>
      )}

      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded shadow-lg text-center text-tertiary">
            <p>{popupMessage}</p>
            {popupCallback ? (
              <div className="mt-4">
                <button onClick={popupCallback} className="bg-red-500 text-white px-4 py-2 rounded mr-2">Yes</button>
                <button onClick={() => setShowPopup(false)} className="bg-gray-500 text-white px-4 py-2 rounded">No</button>
              </div>
            ) : (
              <div className='flex justify-around'>
                  {(showLogin &&
                    <button onClick={handlePopupLogin} className="bg-red-400 text-white py-2 px-4 rounded-md mt-4 hover:bg-tertiary transition duration-300">
                      Log In
                    </button>
                  )}
                  <button onClick={handlePopupClose} className="bg-gray-400 text-white py-2 px-4 rounded-md mt-4 hover:bg-tertiary transition duration-300">
                      Close
                  </button>
              </div>
            )}
          </div>
        </div>
      )}

      <div className='border-b-2 border-tertiary'><span className="invisible text-xs">.</span></div>

      {replyVisibility && comment.children && comment.children.map(childComment => (
        <OneComment
          key={childComment.comment_id}
          comment={childComment}
          liked_comments={liked_comments}
          setParentCommentID={setParentCommentID}
          setCommentReload={setCommentReload}
          level={level + 1} // increment level for child comments
        />
      ))}
    </div>
  );
};

export default OneComment;