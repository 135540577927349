import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import ResetPasswordForm from '../../components/users/ResetPasswordForm';
import { Helmet } from 'react-helmet';

const ResetPassword = () => {
  const [message, setMessage] = useState('');
  const [showForm, setShowForm] = useState(false);
  const location = useLocation();

  const getTokenFromQuery = () => {
    const params = new URLSearchParams(location.search);
    return params.get('token');
  };

  const getEmailFromQuery = () => {
    const params = new URLSearchParams(location.search);
    return params.get('email');
  };

  useEffect(() => {
    const verifyToken = async () => {
      const token = getTokenFromQuery();
      const email = getEmailFromQuery();
      if (token) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/reset-password`, {
            params: { token: token, email: email }
          });
          if (response.data.success) {
            setShowForm(true);
          } else {
            setMessage(response.data.message);
          }
        } catch (error) {
          setMessage('An error occurred. Please try again.'+error);
        }
      } else {
        setMessage('Invalid token.');
      }
    };

    verifyToken();
  }, [location.search]);

  return (
    <div className="flex flex-col justify-center items-center">
      <Helmet>
          <meta name="reset-password" content="Reset password" />
          <title>Reset Password</title>
      </Helmet>
      {message && <p>{message}</p>}
      {showForm && <ResetPasswordForm token={getTokenFromQuery()} />}
    </div>
  );
};

export default ResetPassword;
