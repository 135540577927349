import React from "react";

function Hero () {
    const logo_reference = "/assets/images/common/logo.webp"

    return (
        <div className="flex flex-col justify-center mt-5 mb-10">
            <div className="flex flex-col gap-5 justify-center items-center">
            <a href="/">
                <img src={logo_reference} alt="Logo" loading="lazy" className="w-full h-auto object-cover max-w-40 justify-center items-center" />
            </a>
                {/* <p className="whitespace-nowrap font-thin italic text-tertiary text-xs lg:text-3xl text-center">Sharing and discovering interesting short stories~</p> */}
            </div>
        </div>
    );
}

export default Hero;