import React from "react";
import { useQuery } from 'react-query';
import axios from 'axios';

function LatestUpdateSide () {
    // Fetching books using React Query
    const fetchBooks = async () => {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/`);;
        return response.data;
    };

    const { data, error, isLoading, isError } = useQuery(['books', { latest_update_recommendations: "latest_update_recommendations" }], fetchBooks, {
        refetchOnWindowFocus: false,
        staleTime: 300000, // 5 minutes in milliseconds
        cacheTime: 1800000, // 30 minutes in milliseconds
    });

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div className="flex flex-col my-2 border-1 border-tertiary">
            <p className="text-center underline">Latest Updates</p>
            <ul>
                {data.latestBooks.map((book) => {
                    // Create slug for each book
                    let slug = `${book.book_id}-${book.english_book_name}`;
                    
                    // Remove unwanted characters
                    slug = slug.replace(/[^a-zA-Z0-9 -]/g, "");
                    
                    // Replace spaces and multiple hyphens with a single hyphen
                    slug = slug.replace(/[\s-]+/g, '-');
                    
                    // Convert to lowercase
                    slug = slug.toLowerCase();
                    
                    return (
                        <li key={book.book_id} className="border-tertiary border-2 p-2">
                            <div className="grid grid-cols-5">
                                <a href={`/${slug}`} className="col-span-3 truncate hover:underline hover:cursor-pointer">
                                    {book.english_book_name}
                                </a>
                                <a href={"/" + slug + "/" + book.latest_chapter} target="_blank"
                                className="col-span-2 text-right truncate hover:underline hover:cursor-pointer">
                                    {book.latest_chapter_title}
                                </a>
                                
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

export default LatestUpdateSide;