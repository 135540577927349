import React from 'react';
import { motion } from 'framer-motion';

const LikeButton = ({ liked, onClick, size = 8, isSubmitting }) => {
  return (
    <span className="flex items-center">
      <motion.button
        className={`flex items-center justify-center p-1 rounded-full bg-gray-200 hover:bg-gray-300 focus:outline-none`}
        whileTap={{ scale: 0.9 }}
        onClick={onClick}
        style={{ width: `${size * 2}px`, height: `${size * 2}px` }}
        disabled={isSubmitting}
      >
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill={liked ? "red" : "none"}
          stroke={liked ? "red" : "black"}
          className={`w-${size} h-${size}`}
          initial={{ scale: 1 }}
          animate={{ scale: liked ? 1 : 1 }}
          transition={{ type: "spring", stiffness: 300 }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41 0.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
          />
        </motion.svg>
      </motion.button>
    </span>
  );
};

export default LikeButton;