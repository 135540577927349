import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedUser = localStorage.getItem('user');

    if (token && storedUser) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setIsAuthenticated(true);
      setUser(JSON.parse(storedUser));
    } else {
      setIsAuthenticated(false);
    }

    setLoading(false); // Set loading to false after checking authentication
  }, []);

  const login = async (email, password) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/user/login`, { email, password });
      const { token, refreshToken, user } = response.data;

      localStorage.setItem('token', token);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('user', JSON.stringify({ username: user.username, email: user.email, user_id: user.user_id, email_verified: user.email_verified }));

      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      setIsAuthenticated(true);
      setUser({ username: user.username, email: user.email, user_id: user.user_id, email_verified: user.email_verified });
    } catch (error) {
      console.error('Login failed', error);
      setIsAuthenticated(false);
      setUser(null);
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('user');

    axios.defaults.headers.common['Authorization'] = null;

    setIsAuthenticated(false);
    setUser(null);
  };

  const refreshToken = async () => {
    try {
      const storedRefreshToken = localStorage.getItem('refreshToken');
      if (!storedRefreshToken) {
        throw new Error('No refresh token found');
      }
  
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/user/token`, { refreshToken: storedRefreshToken });
      const { token, refreshToken: newRefreshToken } = response.data;
  
      localStorage.setItem('token', token);
      localStorage.setItem('refreshToken', newRefreshToken);
  
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  
      setIsAuthenticated(true);
      return true; // Indicate that the token was successfully refreshed
    } catch (error) {
      console.error('Token refresh failed', error);
      logout();
      throw error; // Ensure the error is thrown so it can be caught in the interceptor
    }
  };  

  // Axios interceptor for handling token expiration
  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      response => response,
      async error => {
        const originalRequest = error.config;
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
            const success = await refreshToken();
            if (success) {
              originalRequest.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
              return axios(originalRequest);
            }
          } catch (refreshError) {
            console.error('Token refresh failed in interceptor', refreshError);
            return Promise.reject(refreshError);
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []);


  return (
    <AuthContext.Provider value={{ isAuthenticated, user, loading, setIsAuthenticated, setUser, login, logout, refreshToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };

// import React, { createContext, useState, useEffect } from 'react';
// import axios from 'axios';

// const AuthContext = createContext();

// const AuthProvider = ({ children }) => {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   const [user, setUser] = useState(null);

//   useEffect(() => {
//     const checkAuthStatus = async () => {
//       try {
//         const response = await axios.get('/api/user/status');
//         if (response.data.isAuthenticated) {
//           setIsAuthenticated(true);
//           setUser(response.data.user);
//           localStorage.setItem('isAuthenticated', 'true');
//           localStorage.setItem('user', JSON.stringify(response.data.user));
//         } else {
//           setIsAuthenticated(false);
//           localStorage.removeItem('isAuthenticated');
//           localStorage.removeItem('user');
//         }
//       } catch (error) {
//         console.error('Error checking authentication status', error);
//       }
//     };

//     const storedIsAuthenticated = localStorage.getItem('isAuthenticated');
//     const storedUser = localStorage.getItem('user');

//     if (storedIsAuthenticated && storedUser) {
//       setIsAuthenticated(true);
//       setUser(JSON.parse(storedUser));
//     } else {
//       checkAuthStatus();
//     }
//   }, []);

//   const logout = async () => {
//     try {
//       await axios.post('/api/user/logout');
//       setIsAuthenticated(false);
//       setUser(null);
//       localStorage.removeItem('isAuthenticated');
//       localStorage.removeItem('user');
//     } catch (error) {
//       console.error('Error logging out', error);
//     }
//   };

//   return (
//     <AuthContext.Provider value={{ isAuthenticated, user, setIsAuthenticated, setUser, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export { AuthContext, AuthProvider };