import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './index.css';

// import universal components
import Header from './components/Header';
import Footer from './components/Footer';
import CookieNotice from './components/CookieNotice';

// import pages
import HomePage from './pages/HomePage';
import SearchingPage from './pages/SearchingPage';
import BookDetailsPage from './pages/BookDetailsPage';
import ChapterContentPage from './pages/ChapterContentPage';
import About from './pages/About';
import Contact from './pages/Contact';
import DMCA from './pages/DMCA';
import TOS from './pages/TOS';
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Disclaimer from "./pages/Disclamer";
import ErrorPage from './pages/ErrorPage';

import PageTracker from "./components/PageTracker";
import Hero from "./components/Hero";
import AdBlockerDetector from "./components/ads/AdBlockerDetector";

import BooksByOneCriteria from "./pages/BooksByOneCriteria";

import ProtectedRoute from "./components/users/ProtectedRoute";
import LoginPage from "./pages/users/LoginPage";
import RegisterPage from "./pages/users/RegisterPage";
import EmailVerification from "./pages/users/EmailVerification";
import ForgotPassword from "./pages/users/ForgotPassword";
import ResetPassword from "./pages/users/ResetPassword";
import ProfilePage from "./pages/users/ProfilePage";
import LikedBookPage from "./pages/users/LikedBookPage";
import ResetPasswordSuccessful from "./pages/users/ResetPasswordSuccessful";

import { AuthProvider } from "./components/users/AuthContext";
import ReplyNotification from "./pages/users/ReplyNotification";
import { ThemeContext, ThemeProvider } from "./components/ThemeContext";

function App() {
  // Logic to set GA property and control ad display based on hostname
  let gaProperty;

  setTimeout(() => {
    if (window.location.hostname === 'crimsontranslations.com' || window.location.hostname === 'www.crimsontranslations.com') {
      gaProperty = 'G-14V7F8XKFR'; // Main site GA property ID
      // window.showAds = true; // Enable ads
    } else {
      gaProperty = 'G-DYJE63WCMJ'; // Subsite GA property ID
      // window.showAds = false; // Disable ads
    }
    
    // Load Google Tag Manager script
    const gtmScript = document.createElement('script');
    gtmScript.async = true;
    gtmScript.src = `https://www.googletagmanager.com/gtag/js?id=${gaProperty}`;
    document.head.appendChild(gtmScript);

    gtmScript.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      window.gtag = gtag;
      gtag('js', new Date());
      gtag('config', gaProperty);
    };
  }, 1000); // Delay of 1 second

  // Matomo
  // Function to initialize Matomo
  function initMatomo() {
    var _paq = window._paq = window._paq || [];
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);

    // Dynamically set the site ID based on the domain
    if (window.location.hostname === 'crimsontranslations.com' || window.location.hostname === 'www.crimsontranslations.com') {
        _paq.push(['setSiteId', '1']); // Site ID for crimsontranslations.com
    } else {
        _paq.push(['setSiteId', '2']); // Site ID for crimsonnovels.com
    }

    (function() {
        var u="//analytics.crimsontranslations.com/";
        _paq.push(['setTrackerUrl', u+'matomo.php']);
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
    })();
  }

  // Initialize Matomo on page load
  window.addEventListener('load', initMatomo);

  //Theme
  const { theme } = useContext(ThemeContext);

  return (
    <AuthProvider>
      <Router>
        {/* <PageTracker /> */}
        <div className={`flex flex-col flex-grow min-h-screen transition-all duration-200 ${theme}`}>
          <Header className="justify-start" />
          <Hero />
          <AdBlockerDetector />
          <div className="flex-grow">
            <Routes>
              <Route path="/" element={<HomePage />}/>
              <Route path="/searchbook" element={<SearchingPage />}/>
              <Route path="/:bookIDSlug" element={<BookDetailsPage />}/>
              <Route path="/diversity/*" element={<BooksByOneCriteria category="diversity" />}/>
              <Route path="/setting/*" element={<BooksByOneCriteria category="setting" />}/>
              <Route path="/genre/*" element={<BooksByOneCriteria category="genre" />}/>
              <Route path="/tag/*" element={<BooksByOneCriteria category="tag" />}/>
              <Route path="/:bookIDSlug/:chapterID" element={<ChapterContentPage />}/>
              <Route path="/about-us" element={<About />}/>
              <Route path="/contact-us" element={<Contact />}/>
              <Route path="/dmca" element={<DMCA />}/>
              <Route path="/terms-of-service" element={<TOS />}/>
              <Route path="/privacy-policy" element={<PrivacyPolicy />}/>
              <Route path="/disclaimer" element={<Disclaimer />}/>

              {/* user route */}
              <Route path="/user/login" element={<LoginPage />}/>
              <Route path="/user/register" element={<RegisterPage />}/>
              <Route path="/user/verify-email" element={<EmailVerification />}/>
              <Route path="/user/forgot-password" element={<ForgotPassword />}/>
              <Route path="/user/reset-password" element={<ResetPassword />}/>
              <Route path="/user/reset-password-successful" element={<ResetPasswordSuccessful />}/>
              
              {/* Protect these routes with JWT */}
              <Route path="/user/profile" element={<ProtectedRoute element={ProfilePage} />} />
              <Route path="/user/liked-story" element={<ProtectedRoute element={LikedBookPage} />} />
              <Route path="/user/reply-notification" element={<ProtectedRoute element={ReplyNotification} />} />

              <Route path="*" element={<HomePage />} />
            </Routes>
          </div>
            <CookieNotice />
            <Footer className="justify-end" />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;