import React from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { Helmet } from 'react-helmet';

import Hero from "../components/Hero";
import BookList from "../components/BookList";
import Sidebar from "../components/Sidebar";
import BookCards from '../components/BookCards';
import DisplayHorizontal from '../components/ads/DisplayHorizontal';
import InFeed from '../components/ads/InFeed';
import DisplayVertical from '../components/ads/DisplayVertical';
import InArticle from '../components/ads/InArticle';

function HomePage () {
    document.title = "Crimson Translations";

    // Fetching books using React Query
    const fetchBooks = async () => {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/`);;
        return response.data;
    };

    const { data, error, isLoading, isError } = useQuery(['books', { latest_update_recommendations: "latest_update_recommendations" }], fetchBooks, {
        refetchOnWindowFocus: false,
        staleTime: 300000, // 5 minutes in milliseconds
        cacheTime: 1800000, // 30 minutes in milliseconds
    });

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div className="flex flex-col m-5">
            <Helmet>
                <meta name="description" content="Crimson Translations - Sharing and discovering interesting short stories~" />
                <title>Crimson Translations</title>
            </Helmet>
            <div className='flex flex-col lg:flex-row justify-center items-center lg:items-start max-w-full'>
                <div className='flex flex-col gap-10 max-w-full'>
                    <div className='flex flex-col justify-center items-center mb-4 lg:mb-0 lg:mr-5'>
                        <h1 className='mb-3 text-3xl text-center font-bold underline'>Latest Updates</h1>
                        <div className='flex'>
                            <BookCards book_list={data.latestBooks} />
                        </div>
                    </div>
                    <InArticle />
                    <div className='flex flex-col justify-center items-center mb-4 lg:mb-0 lg:mr-5'>
                        <h1 className='mb-3 text-3xl text-center font-bold underline'>BL Recommendations</h1>
                        <div className='flex'>
                            <BookCards book_list={data.randomBLBooks} />
                        </div>
                    </div>
                    <InArticle />
                    <div className='flex flex-col justify-center items-center mb-4 lg:mb-0 lg:mr-5'>
                        <h1 className='mb-3 text-3xl text-center font-bold underline'>Non-BL Recommendations</h1>
                        <div className='flex'>
                            <BookCards book_list={data.randomNonBLBooks} />
                        </div>
                    </div>
                    <InArticle />
                </div>
                <div className='justify-start items-start lg:max-w-xs'>
                    <Sidebar />
                </div>
            </div>
        </div>
    );
}

export default HomePage;