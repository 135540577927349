import React from "react";

function Sidebar () {
    const kofi_reference = "/assets/images/common/Ko-fi.webp";
    const discord_reference = "/assets/images/common/Discord.webp";
    const facebook_reference = "/assets/images/common/Facebook.webp";
    const instagram_reference = "/assets/images/common/Instagram.webp";
    const twitter_reference = "/assets/images/common/Twitter.webp";

    return (
        <div className="flex flex-col p-3 border-2 border-tertiary gap-10">
            <div className="flex flex-col">
                <p className="text-lg underline">Support through Ko-fi!</p>
                <div className="justify-center">
                    <a href="https://ko-fi.com/crimsontranslations" target="_blank" className="max-w-60">
                        <img src={kofi_reference} loading="lazy" className="max-w-60" alt="Ko-fi logo" />
                    </a>
                </div>
            </div>

            <div className="flex flex-col">
                <p className="text-lg underline">Join our Discord!</p>
                <div className="justify-center">
                    <a href="https://discord.gg/AfrCYY7nmS" target="_blank" className="max-w-60">
                        <img src={discord_reference} loading="lazy" className="max-w-60" alt="Discord logo" />
                    </a>
                </div>
            </div>

            <div className="flex flex-col">
                <p className="text-lg underline">Get latest update news on social media platforms!</p>
                <div className="flex gap-2">
                    <a href="https://www.facebook.com/profile.php?id=61558454804776" target="_blank" className="max-w-14">
                        <img src={facebook_reference} loading="lazy" alt="Facebook logo" />
                    </a>
                    <a href="https://www.instagram.com/crimsontranslations/" target="_blank" className="max-w-14">
                        <img src={instagram_reference} loading="lazy" alt="Instagram logo" />
                    </a>
                    {/* <img src={twitter_reference} loading="lazy" className="max-w-20" alt="Twitter logo" /> */}
                </div>
            </div>
        </div>
    );
}

export default Sidebar;