import React, { useContext, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import BookCards from '../../components/BookCards';
import { AuthContext } from '../../components/users/AuthContext';
import { useNavigate } from 'react-router-dom';

function LikedBookPage() {
    const { user, refreshToken } = useContext(AuthContext);
    const [pageNum, setPageNum] = useState(1);
    const [sortingCriteria, setSortingCriteria] = useState('last_updated');

    const navigate = useNavigate();

    useEffect(() => {
        const checkAuth = async () => {
            if (!user) {
                navigate('/user/login'); // Redirect to login page if not setIsAuthenticated
            } else {
                try {
                    await refreshToken(); // Attempt to refresh token if authenticated
                } catch (error) {
                    navigate('/user/login'); // Redirect to login if refresh token fails
                }
            }
        };
        
        checkAuth();
    }, [user, navigate, refreshToken]);

    const handlePageChange = (newPageNum) => {
        setPageNum(newPageNum);
    }

    const fetchLikedBooks = async () => {
        if (user) {
            await refreshToken();
        }
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/liked-story`, {
            params: {
                pageNum: pageNum,
                sort_by: sortingCriteria
            }
        });
        return response.data;
    };

    const fetchUncheckedUpdatesCount = async () => {
        if (user) {
            await refreshToken();
        }
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/liked-story/unchecked-updates-count`);
        return response.data.uncheckedUpdatesCount;
    };

    const markHasNewUpdatesAsFalse = async () => {
        if (user) {
            await refreshToken();
        }
        await axios.put(`${process.env.REACT_APP_BASE_URL}/api/user/liked-story/mark-updates-checked`);
    };

    const { data, error, isLoading, isError, refetch } = useQuery(['liked_books', user.user_id, sortingCriteria, pageNum], fetchLikedBooks, {
        refetchOnWindowFocus: false,
        staleTime: 300000, // 5 minutes in milliseconds
        cacheTime: 1800000, // 30 minutes in milliseconds
    });

    useEffect(() => {
        refetch();
    }, [pageNum]);

    const { data: uncheckedUpdatesCount } = useQuery(['uncheckedUpdatesCount', user.user_id], fetchUncheckedUpdatesCount, {
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (data) {
            markHasNewUpdatesAsFalse();
        }
    }, [data]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error: {error.message}</div>;
    }

    let pages = "";
    if (!isLoading && !isError) {
        pages = [...Array(data.totalPages).keys()].map(n => n + 1); // Create an array from 1 to totalPages
    }

    return (
        <div className="flex flex-col m-5">
            <Helmet>
                <meta name="liked_stories" content="Stories liked by users" />
                <title>Liked Stories</title>
            </Helmet>
            <div className='flex flex-col justify-center items-center mb-4 lg:mb-0 lg:mr-5'>
                <h1 className='mb-3 text-3xl text-center font-bold underline'>Liked Stories</h1>
                <div className="flex items-center">
                    <label className="mr-2">Sort by</label>
                    <select 
                        value={sortingCriteria} 
                        onChange={(e) => setSortingCriteria(e.target.value)} 
                        className="border p-2 rounded-md text-tertiary"
                    >
                        <option value="last_updated">Latest Updates</option>
                        <option value="like_count">Most Liked</option>
                        <option value="liked_time">Most Recently Liked</option>
                    </select>
                </div>

                {uncheckedUpdatesCount > 0 && 
                    <div className="my-4 text-lg text-red-500 text-center">
                        {uncheckedUpdatesCount} book{uncheckedUpdatesCount > 1 ? 's have' : ' has'} new updates.
                    </div>
                }

                <div className='flex'>
                    <BookCards book_list={data.books} />
                </div>
                <div className="flex justify-center items-center max-w-full gap-2">
                    {pages.map((page) => (
                        <button
                            key={page}
                            className={`px-3 py-1 ${pageNum === page ? '' : 'text-red-500 hover:underline'} font-bold`}
                            onClick={() => handlePageChange(page)}
                            disabled={pageNum === page}
                        >
                            {page}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default LikedBookPage;