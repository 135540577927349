import React, { createContext, useState, useEffect } from 'react';

// Create the ThemeContext
const ThemeContext = createContext();

// Define available themes
const themes = {
  crimson: 'bg-primary text-tertiary',
  jade: 'bg-green-100 text-tertiary',
  azure: 'bg-blue-100 text-tertiary',
  light: 'bg-white text-black',
  dark: 'bg-black text-white',
  gray: 'bg-gray-700 text-white',
};

const ThemeProvider = ({ children }) => {
  const savedTheme = localStorage.getItem('theme') || 'crimson';
  const [themeName, setThemeName] = useState(savedTheme);

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) setThemeName(savedTheme);
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', themeName);
  }, [themeName]);

  const setTheme = (name) => {
    if (themes[name]) {
      setThemeName(name);
    } else {
      console.warn(`Theme "${name}" is not defined.`);
    }
  };

  return (
    <ThemeContext.Provider value={{
      theme: themes[themeName],
      themeName,
      setTheme,
    }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProvider };
