import React from "react";
import InArticle from "./ads/InArticle";

function Info ( { title, content } ) {
    return (
        <div className="justify-center items-center">
            <div className="py-5 mb-5 justify-center items-center border-b-2 border-tertiary">
                <h2 className="text-2xl font-bold text-center">{title}</h2>
            </div>
            <div className="p-2 break-words w-full">
                <p className="text-lg text-justify leading-10" dangerouslySetInnerHTML={{ __html: content }}></p>
            </div>
        </div>
    );
}

export default Info;