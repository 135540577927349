import React, { useState } from 'react';
import axios from 'axios';

const ResetPasswordForm = ({ token }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [message, setMessage] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [honeypot, setHoneypot] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (newPassword !== confirmedPassword) {
      setMessage('New password and confirmed password are not the same, please check again.');
      setTimeout(() => setIsSubmitting(false), 1000); // Re-enable the button after 1 second
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/user/reset-password`, { token, newPassword, honeypot });
      setMessage(response.data.message);
      if (response.data.success = true) {
        window.location.href = '/user/reset-password-successful';
      }
    } catch (error) {
      setMessage('An error occurred. Please try again.');
    } finally {
      setTimeout(() => setIsSubmitting(false), 1000); // Re-enable the button after 1 second
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col justify-center items-center w-full lg:w-1/2 p-4 rounded-md">
      <h2 className="text-2xl mb-4">Reset Password</h2>
      {message && <p className="text-red-500 mb-4">{message}</p>}
      
      <div className="w-full mb-4">
        <div className="grid grid-cols-4 gap-2 mb-4 items-center">
          <label className='col-span-1 text-center'>New Password:</label>
          <input
            className='col-span-3 p-2 border border-tertiary text-tertiary rounded-md'
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div className="grid grid-cols-4 gap-2 items-center">
          <label className='col-span-1 text-center'>Confirm New Password:</label>
          <input
            className='col-span-3 p-2 border border-tertiary text-tertiary rounded-md'
            type="password"
            value={confirmedPassword}
            onChange={(e) => setConfirmedPassword(e.target.value)}
            required
          />
        </div>
        {/*honeypot*/}
        <input
          className="hidden text-tertiary"
          type="text"
          name="phone_number"
          value={honeypot}
          onChange={(e) => setHoneypot(e.target.value)}
          tabIndex="-1"
          autoComplete="off"
        />
      </div>
      
      <button type="submit" disabled={isSubmitting} className="bg-red-400 text-white py-2 px-4 rounded-md mb-2 hover:bg-tertiary transition duration-300">Reset Password</button>
    </form>

  );
};

export default ResetPasswordForm;